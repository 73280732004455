import { Action } from '@ngrx/store';

import { PfLocation } from '../../../typings/app';
import { GeocodeItem, SearchAddress } from '../../../typings/map';
import { PropertyProfileData, SendDocumentData } from '../../../typings/property-profile';
import { SearchByAddressData } from '../../../typings/search';

export enum PropertyProfileActionTypes {
  ClearAllProfiles = '[Property Profile] Clear All Profiles',
  GeocodeAddresses = '[Property Profile] Locate Comparable Sales Addresses',
  GetComparableSalesPropertyProfileData = '[Property Profile] Get Comparable Sales Property Profile Data',
  GetPlatMap = '[Property Profile] Get PlatMap',
  GetPlatMapFail = '[Property Profile] Get PlatMap Fail',
  GetPlatMapSuccess = '[Property Profile] Get PlatMap Success',
  GetPropertyProfileData = '[Property Profile] Get Property Profile Data',
  GetPropertyProfileDataSuccess = '[Property Profile] Get Property Profile Data Success',
  GetPropertyProfileDataWithResults = '[Property Profile] Get Property Profile Data With Search Results',
  GetPropertyProfileDataWithoutResults = '[Property Profile] Get Property Profile Data Without Search Results',
  PropertyProfileExport = '[Property Profile] PropertyProfileExport',
  PropertyProfileExportFail = '[Property Profile] PropertyProfileExportFail',
  PropertyProfileExportSuccess = '[Property Profile] PropertyProfileExportSuccess',
  ResetPropertyProfileExportPdf = '[Property Profile] ResetPropertyProfileExportPdf',
  ResetSelectedProfile = '[Property Profile] Reset Selected Profile',
  SavePropertyProfileScroll = '[Property Profile] SavePropertyProfileScroll',
  SendDocumentImage = '[Property Profile] Send Document Image',
  SendDocumentImageFail = '[Property Profile] Send Document Image Fail',
  SendDocumentImageSuccess = '[Property Profile] Send Document Image Success',
  SetComparableSalesMarkers = '[Property Profile] Set Comparable Sales Markers'
}

export class GetPropertyProfileData implements Action {
  readonly type = PropertyProfileActionTypes.GetPropertyProfileData;

  constructor(
    public payload: {
      data: SearchAddress;
      savePropertyProfile?: boolean;
    }
  ) {}
}

export class GetPropertyProfileDataWithoutResults implements Action {
  readonly type = PropertyProfileActionTypes.GetPropertyProfileDataWithoutResults;
  constructor(
    public payload: {
      data: SearchAddress;
      savePropertyProfile?: boolean;
    }
  ) {}
}

export class GetPropertyProfileDataWithResults implements Action {
  readonly type = PropertyProfileActionTypes.GetPropertyProfileDataWithResults;
  constructor(
    public payload: {
      data: { d: PropertyProfileData };
      savePropertyProfile?: boolean;
    }
  ) {}
}

export class GetComparableSalesPropertyProfileData implements Action {
  readonly type = PropertyProfileActionTypes.GetComparableSalesPropertyProfileData;

  constructor(public payload: SearchByAddressData) {}
}
export class GetPropertyProfileDataSuccess implements Action {
  readonly type = PropertyProfileActionTypes.GetPropertyProfileDataSuccess;

  constructor(public payload: { propertyReport: PropertyProfileData; providerId: string }) {}
}
export class ResetSelectedProfile implements Action {
  readonly type = PropertyProfileActionTypes.ResetSelectedProfile;

  constructor() {}
}

export class SetComparableSalesMarkers implements Action {
  readonly type = PropertyProfileActionTypes.SetComparableSalesMarkers;

  constructor(public payload: PfLocation[]) {}
}

export class GetPlatMap implements Action {
  readonly type = PropertyProfileActionTypes.GetPlatMap;

  constructor(public payload: { address: string; deliveryEmail: string; zip: string }) {}
}
export class GetPlatMapSuccess implements Action {
  readonly type = PropertyProfileActionTypes.GetPlatMapSuccess;

  constructor() {}
}
export class GetPlatMapFail implements Action {
  readonly type = PropertyProfileActionTypes.GetPlatMapFail;

  constructor(public payload: Error) {}
}

export class SendDocumentImage implements Action {
  readonly type = PropertyProfileActionTypes.SendDocumentImage;

  constructor(public payload: Partial<SendDocumentData>) {}
}
export class SendDocumentImageSuccess implements Action {
  readonly type = PropertyProfileActionTypes.SendDocumentImageSuccess;

  constructor() {}
}
export class SendDocumentImageFail implements Action {
  readonly type = PropertyProfileActionTypes.SendDocumentImageFail;

  constructor(public payload: Error) {}
}

export class ClearAllProfiles implements Action {
  readonly type = PropertyProfileActionTypes.ClearAllProfiles;

  constructor() {}
}

export class GeocodeAddresses implements Action {
  readonly type = PropertyProfileActionTypes.GeocodeAddresses;

  constructor(public payload: { addresses: GeocodeItem[]; onSuccess(markers: PfLocation[]): Action[] }) {}
}

export class PropertyProfileExport implements Action {
  readonly type = PropertyProfileActionTypes.PropertyProfileExport;

  constructor() {}
}

export class PropertyProfileExportSuccess implements Action {
  readonly type = PropertyProfileActionTypes.PropertyProfileExportSuccess;

  constructor(public payload: Blob) {}
}

export class PropertyProfileExportFail implements Action {
  readonly type = PropertyProfileActionTypes.PropertyProfileExportFail;

  constructor(public payload: Error) {}
}

export class ResetPropertyProfileExportPdf implements Action {
  readonly type = PropertyProfileActionTypes.ResetPropertyProfileExportPdf;

  constructor() {}
}

export class SavePropertyProfileScroll implements Action {
  readonly type = PropertyProfileActionTypes.SavePropertyProfileScroll;

  constructor(public payload: number) {}
}

export type PropertyProfileActions =
  | GetPropertyProfileData
  | GetPropertyProfileDataWithoutResults
  | GetPropertyProfileDataWithResults
  | GetComparableSalesPropertyProfileData
  | GetPropertyProfileDataSuccess
  | ResetSelectedProfile
  | ClearAllProfiles
  | SetComparableSalesMarkers
  | GetPlatMap
  | GetPlatMapSuccess
  | GetPlatMapFail
  | SendDocumentImage
  | SendDocumentImageSuccess
  | GeocodeAddresses
  | SendDocumentImageFail
  | PropertyProfileExport
  | PropertyProfileExportSuccess
  | PropertyProfileExportFail
  | ResetPropertyProfileExportPdf
  | SavePropertyProfileScroll;
