import { appBuildNumber, appVersion } from '../../../../environments/environment';
import { AppInfoData, AuthData, RepDataProvider } from '../../../typings/app';
import { DeviceData } from '../../../typings/device';
import { TermsToAccept } from '../../../typings/terms';

import * as generalInfoActions from '../../actions/app-config/general-info';
import * as userProfileActions from '../../actions/app-settings/user-profile';
import * as appParseActions from '../../actions/parse/parse-implementation';

export interface State {
  appInfo: AppInfoData;
  authData: AuthData;
  deviceData: DeviceData;
  error: Error | null;
  isAuthenticated: boolean | null;
  newFarmKeyTermsToAccept: TermsToAccept;
  newPropertyKeyTermsToAccept: TermsToAccept;
  payments: {
    error: Error | null;
    isLoaded: boolean;
  };
  pending: boolean;
  propertyFarm: {
    key: string;
    lastUpdatedTime: number;
  } | null;
  propertyProfile: {
    key: string;
    lastUpdatedTime: number;
  } | null;
  repData: RepDataProvider;
  repDataLoadSuccess: boolean;
  succeeded: boolean;
}

export const initialState: State = {
  appInfo: {
    baseColors: {
      chartsColorPrimary: '',
      colors: {
        danger: '',
        dark: '',
        light: '',
        medium: '',
        primary: '',
        secondary: '',
        success: '',
        warn: ''
      }
    },
    baseUrls: {
      alternateAppIconUrl: '',
      logoUrl: ''
    },
    companyConfig: {
      alternateAppIconName: '',
      autoSubscribeFarmAlerts: false,
      consolidatedParseAppKey: '',
      farmAlertsEnabled: false,
      parseUrlType: '',
      showAreaSalesProfileItem: false,
      showComparableSalesProfileItem: false,
      showTransactionHistoryProfileItem: false,
      targetName: '',
      titleCompanyShortName: ''
    },
    currentAppIcon: 'default'
  },
  authData: {
    password: '',
    repCode: '',
    username: ''
  },
  deviceData: {
    appName: 'container',
    appVersion: `${appVersion}(${appBuildNumber})`,
    deviceDimensions: '',
    deviceType: '',
    officeCode: '',
    os: '',
    osVersion: '',
    userName: ''
  },
  error: null,
  isAuthenticated: null,
  newFarmKeyTermsToAccept: {
    html: '',
    version: 0
  },
  newPropertyKeyTermsToAccept: {
    html: '',
    version: 0
  },
  payments: {
    error: null,
    isLoaded: false
  },
  pending: false,
  propertyFarm:
    {
      key: 'BOGUS_FARM_KEY',
      lastUpdatedTime: 0
    } || null,
  propertyProfile:
    {
      key: 'BOGUS_PROPERTY_KEY',
      lastUpdatedTime: 0
    } || null,
  repData: {
    calculatorsEnabled: false,
    dataPortalName: '',
    hideContactCard: false,
    inAppProducts: null,
    isNetSheetEnabled: false,
    orderTitleEmail: '',
    phoneAndEmailPurchase: false,
    primaryPhoneType: '',
    providerId: '',
    showDocLinks: false,
    subscriptionPlan: '',
    walkingFarmEnabled: false
  },
  repDataLoadSuccess: false,
  succeeded: false
};
export function reducer(
  state = initialState,
  action: generalInfoActions.GeneralConfigAction | userProfileActions.UserProfileAction | appParseActions.ParseActions
) {
  switch (action.type) {
    case generalInfoActions.GeneralConfigActionTypes.GetAppSettingsData:
    case generalInfoActions.GeneralConfigActionTypes.GetPropertyProfileKey:
    case generalInfoActions.GeneralConfigActionTypes.GetPropertyFarmKey:
    case generalInfoActions.GeneralConfigActionTypes.GetUserAcceptedTermsForFarmData:
    case generalInfoActions.GeneralConfigActionTypes.GetUserAcceptedTermsForPropertyData: {
      return {
        ...state,
        error: null,
        pending: true,
        succeeded: false
      };
    }

    case generalInfoActions.GeneralConfigActionTypes.AcceptNewPropertyKeyTerms: {
      return {
        ...state,
        newPropertyKeyTermsToAccept: action.payload
      };
    }

    case generalInfoActions.GeneralConfigActionTypes.AcceptNewFarmKeyTerms: {
      return {
        ...state,
        newFarmKeyTermsToAccept: action.payload
      };
    }

    case generalInfoActions.GeneralConfigActionTypes.ResetNewPropertyKeyTerms: {
      return {
        ...state,
        newPropertyKeyTermsToAccept: initialState.newPropertyKeyTermsToAccept
      };
    }

    case generalInfoActions.GeneralConfigActionTypes.ResetNewFarmKeyTerms: {
      return {
        ...state,
        newFarmKeyTermsToAccept: initialState.newFarmKeyTermsToAccept
      };
    }

    case generalInfoActions.GeneralConfigActionTypes.SetRepDataProvider: {
      return {
        ...state,
        repData: action.payload
      };
    }

    case generalInfoActions.GeneralConfigActionTypes.SetSubscriptionPlan: {
      return {
        ...state,
        repData: {
          ...state.repData,
          subscriptionPlan: action.payload
        }
      };
    }

    case generalInfoActions.GeneralConfigActionTypes.SetAppInfo: {
      return {
        ...state,
        appInfo: {
          ...state.appInfo,
          ...action.payload
        },
        error: null,
        pending: false,
        succeeded: true
      };
    }

    case generalInfoActions.GeneralConfigActionTypes.GetPropertyProfileKeySuccess: {
      return {
        ...state,
        error: null,
        pending: false,
        propertyProfile: action.payload,
        succeeded: true
      };
    }
    case generalInfoActions.GeneralConfigActionTypes.GetPropertyFarmKeySuccess: {
      return {
        ...state,
        error: null,
        pending: false,
        propertyFarm: action.payload,
        succeeded: true
      };
    }

    case generalInfoActions.GeneralConfigActionTypes.GetAppSettingsDataSuccess: {
      return {
        ...state,
        error: null,
        pending: false,
        repDataLoadSuccess: true,
        succeeded: true
      };
    }

    case generalInfoActions.GeneralConfigActionTypes.GetUserAcceptedTermsForFarmDataSuccess:
    case generalInfoActions.GeneralConfigActionTypes.GetUserAcceptedTermsForPropertyDataSuccess: {
      return {
        ...state,
        error: null,
        pending: false,
        succeeded: true
      };
    }

    case generalInfoActions.GeneralConfigActionTypes.UpdateGeneralInfoFromInternalStorage: {
      return {
        ...state,
        appInfo: action.payload.appInfo,
        authData: action.payload.authData,
        deviceData: {
          ...action.payload.deviceData,
          appVersion: initialState.deviceData.appVersion
        },
        error: null,
        isAuthenticated: action.payload.isAuthenticated,
        pending: false,
        propertyFarm: action.payload.propertyFarm,
        propertyProfile: action.payload.propertyProfile,
        repData: action.payload.repData,
        succeeded: false
      };
    }

    case generalInfoActions.GeneralConfigActionTypes.ResetKeys: {
      return {
        ...state,
        propertyFarm: action.payload.propertyFarm,
        propertyProfile: action.payload.propertyProfile
      };
    }

    case generalInfoActions.GeneralConfigActionTypes.ResetGeneralInfo: {
      return {
        ...state,
        appInfo: initialState.appInfo,
        authData: initialState.authData,
        deviceData: {
          ...state.deviceData,
          appName: 'container',
          officeCode: '',
          userName: ''
        },
        error: null,
        isAuthenticated: false,
        pending: false,
        propertyFarm: initialState.propertyFarm,
        propertyProfile: initialState.propertyProfile,
        repData: initialState.repData,
        repDataLoadSuccess: false,
        succeeded: false
      };
    }

    case generalInfoActions.GeneralConfigActionTypes.ResetGeneralInfoErrors: {
      return {
        ...state,
        error: null,
        pending: false,
        succeeded: false
      };
    }

    case generalInfoActions.GeneralConfigActionTypes.GetPropertyProfileKeyFail:
    case generalInfoActions.GeneralConfigActionTypes.GetPropertyFarmKeyFail:
    case generalInfoActions.GeneralConfigActionTypes.GetUserAcceptedTermsForFarmDataFail:
    case generalInfoActions.GeneralConfigActionTypes.GetUserAcceptedTermsForPropertyDataFail:
    case generalInfoActions.GeneralConfigActionTypes.GetDeviceDataFail: {
      return {
        ...state,
        error: action.payload,
        pending: false,
        succeeded: false
      };
    }

    case generalInfoActions.GeneralConfigActionTypes.GetAppSettingsDataFail: {
      return {
        ...state,
        error: action.payload,
        pending: false,
        repDataLoadSuccess: false,
        succeeded: false
      };
    }

    case generalInfoActions.GeneralConfigActionTypes.ResetAppInfo: {
      return {
        ...state,
        appInfo: initialState.appInfo,
        error: null,
        pending: false,
        succeeded: false
      };
    }
    case generalInfoActions.GeneralConfigActionTypes.UpdatePassword: {
      return {
        ...state,
        authData: {
          ...state.authData,
          password: action.payload
        }
      };
    }
    case generalInfoActions.GeneralConfigActionTypes.UpdateAuthData: {
      return {
        ...state,
        authData: {
          ...state.authData,
          ...action.payload
        },
        deviceData: {
          ...state.deviceData,
          officeCode: action.payload.repCode,
          userName: action.payload.username || ''
        }
      };
    }
    case generalInfoActions.GeneralConfigActionTypes.UpdateCurrentAppIcon: {
      return {
        ...state,
        appInfo: {
          ...state.appInfo,
          currentAppIcon: action.payload
        }
      };
    }

    case generalInfoActions.GeneralConfigActionTypes.GetDeviceData: {
      return {
        ...state,
        error: null,
        pending: true,
        succeeded: false
      };
    }
    case generalInfoActions.GeneralConfigActionTypes.GetDeviceDataSuccess: {
      return {
        ...state,
        deviceData: {
          ...state.deviceData,
          ...action.payload
        },
        error: null,
        pending: false,
        succeeded: true
      };
    }
    case generalInfoActions.GeneralConfigActionTypes.ResetDeviceData: {
      return {
        ...state,
        deviceData: initialState.deviceData,
        error: null,
        pending: false,
        succeeded: false
      };
    }
    case generalInfoActions.GeneralConfigActionTypes.UpdateDeviceData: {
      return {
        ...state,
        deviceData: {
          ...state.deviceData,
          ...action.payload
        },
        pending: false,
        succeeded: true
      };
    }

    case generalInfoActions.GeneralConfigActionTypes.SetRepCode: {
      const repCode = action.payload.repCode ? action.payload.repCode : state.deviceData.officeCode;

      return {
        ...state,
        authData: {
          ...state.authData,
          repCode
        },
        deviceData: {
          ...state.deviceData,
          officeCode: repCode
        }
      };
    }

    case userProfileActions.UserProfileActionTypes.AuthenticateUserSuccess: {
      return {
        ...state,
        authData: {
          ...state.authData,
          ...action.payload
        },
        deviceData: {
          ...state.deviceData,
          userName: action.payload.username
        }
      };
    }

    case appParseActions.ParseActionTypes.GetRegisteredUserSuccess:
    case appParseActions.ParseActionTypes.CreateUserSuccess: {
      return {
        ...state,
        isAuthenticated: true
      };
    }

    case generalInfoActions.GeneralConfigActionTypes.PaymentsFlowLoadedSuccess:
      return {
        ...state,
        payments: {
          ...state.payments,
          isLoaded: true
        }
      };

    case generalInfoActions.GeneralConfigActionTypes.PaymentsFlowLoadedFail:
      return {
        ...state,
        payments: {
          ...state.payments,
          error: action.payload,
          isLoaded: false
        }
      };

    default: {
      return state;
    }
  }
}

export const getIsAuthenticated = (state: State) => state?.isAuthenticated;
export const getIsPaymentsLoaded = (state: State) => state?.payments.isLoaded;
export const getAppInfo = (state: State) => state?.appInfo;
export const getAppInfoLogoUrl = (state: State) => state?.appInfo.baseUrls.logoUrl;
export const getAppInfoColors = (state: State) => state?.appInfo?.baseColors?.colors;
export const getAppInfoRgbColorNumbers = (state: State) => state?.appInfo.baseColors.chartsColorPrimary;
export const getAppInfoCompanyAlternateIconUrl = (state: State) => state?.appInfo.baseUrls.alternateAppIconUrl;
export const getAppInfoParseUrlType = (state: State) => state?.appInfo.companyConfig.parseUrlType;
export const getAppInfoCompanyShortName = (state: State) => state?.appInfo.companyConfig.titleCompanyShortName;
export const getAppInfoCompanyAlternateIcon = (state: State) => state?.appInfo.companyConfig.alternateAppIconName;
export const getAppInfoShowTransactionHistory = (state: State) =>
  state?.appInfo.companyConfig.showTransactionHistoryProfileItem;
export const getAppInfoShowComparableSales = (state: State) =>
  state?.appInfo.companyConfig.showComparableSalesProfileItem;
export const getAppInfoShowAreaSales = (state: State) => state?.appInfo.companyConfig.showAreaSalesProfileItem;
export const getAppInfoFarmAlertsEnabled = (state: State) => state?.appInfo.companyConfig.farmAlertsEnabled;
export const getAppInfoAutoSubscribeFarmAlerts = (state: State) => state?.appInfo.companyConfig.autoSubscribeFarmAlerts;
export const getAppInfoConsolidatedParseAppKey = (state: State) =>
  state?.appInfo.companyConfig.consolidatedParseAppKey.toLowerCase();
export const getCurrentAppIcon = (state: State) => state?.appInfo.currentAppIcon;

export const getPropertyFarm = (state: State) => state?.propertyFarm;
export const getPropertyProfile = (state: State) => state.propertyProfile;
export const getFarmKey = (state: State) => state?.propertyFarm.key;
export const getPropertyKey = (state: State) => state.propertyProfile.key;

export const getNewPropertyKeyTermsToAccept = (state: State) => state.newPropertyKeyTermsToAccept;
export const getNewFarmKeyTermsToAccept = (state: State) => state.newFarmKeyTermsToAccept;

export const getRepData = (state: State) => state.repData;
export const getRepDataProviderId = (state: State) => state.repData.providerId;
export const getRepDataPortalName = (state: State) => state.repData.dataPortalName;
export const getRepDataCalculatorsEnabled = (state: State) => state.repData.calculatorsEnabled;
export const getRepDataWalkingFarmEnabled = (state: State) => state.repData.walkingFarmEnabled;
export const getRepDataIsNetSheetEnabled = (state: State) => state.repData.isNetSheetEnabled;
export const getRepDataHideContactCard = (state: State) => state.repData.hideContactCard;
export const getRepDataShowDocLinks = (state: State) => state.repData.showDocLinks;
export const getRepDataOrderTitleEmail = (state: State) => state.repData.orderTitleEmail;
export const getInAppProducts = (state: State) => state.repData.inAppProducts;
export const getPhoneAndEmailPurchase = (state: State) => state.repData.phoneAndEmailPurchase;

export const getDeviceData = (state: State) => state?.deviceData;
export const getDeviceDataAppName = (state: State) => state?.deviceData.appName;

export const getAuthData = (state: State) => state.authData;

export const getSucceeded = (state: State) => state.succeeded;
export const getError = (state: State) => state.error;

export const getRepDataSuccess = (state: State) => state.repDataLoadSuccess;
