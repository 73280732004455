<ion-header class="tw-h-20">
  <ion-tabs (ionTabsDidChange)="onTabsChange()">
    <ion-tab-bar class="tw-h-full">
      <ion-grid class="ion-no-padding tw-h-full">
        <ion-row class="ion-no-padding tw-h-full">
          <ion-col size="0" size-md="3" size-lg="2" class="ion-no-padding tw-flex tw-px-4">
            <img
              class="tw-max-h-20 tw-max-w-none tw-py-2"
              [src]="logoUrl"
              alt="{{ brandName + ' logo' }}"
              (click)="goHome()"
            />
          </ion-col>

          <ion-col size-md="6" offset-md="3" size-lg="5" offset-lg="5" class="tw-flex">
            <ion-row class="ion-no-padding tw-flex-1 tw-justify-center lg:tw-justify-end">
              <ng-container *ngFor="let tab of tabs; trackBy: trackByActiveTab">
                <ion-col size="3" *ngIf="tab.isVisible" class="ion-no-padding">
                  <ion-tab-button class="ion-no-padding" (click)="handleTabNavigation(tab.route)">
                    <ng-content
                      *ngTemplateOutlet="tab.isSvg ? svgIconTemplate : ionicIconTemplate; context: { tab: tab }"
                    ></ng-content>

                    <ion-label
                      color="primary"
                      [ngClass]="{ 'tw-font-bold': tab.isActive, 'tw-opacity-80': !tab.isActive }"
                      [innerHTML]="tab.title"
                    ></ion-label>
                  </ion-tab-button>
                </ion-col>
              </ng-container>
            </ion-row>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-tab-bar>
  </ion-tabs>
</ion-header>

<ng-template #ionicIconTemplate let-tab="tab">
  <ion-icon
    color="primary"
    [class]="tab.icon"
    [ngClass]="{
      'tw-h-6 tw-w-8 tw-text-2xl': true,
      'tw-opacity-80': !tab.isActive
    }"
  ></ion-icon>
</ng-template>

<ng-template #svgIconTemplate let-tab="tab">
  <ion-icon
    class="smd-svg-icon-color tw-h-6 tw-w-8 tw-text-2xl"
    [ngClass]="{ 'tw-opacity-80': !tab.isActive }"
    [src]="tab.icon"
  ></ion-icon>
</ng-template>
