import { Action } from '@ngrx/store';

import { PfLocation } from '../../../typings/app';
import {
  FarmCountFilters,
  FarmData,
  FarmTurnOver,
  PolygonFarmCoordinates,
  PropertyData,
  RadiusFarmCoordinates
} from '../../../typings/walking-farm';

export enum FarmActionTypes {
  ClearAllSavedFarms = '[Farm] Clear All Saved Farms',
  FilterViewedFarm = '[Farm] Filter Viewed Farm',
  GetFarmCount = '[Farm] Get Farm Count',
  GetFarmCountFail = '[Farm] Get Farm Count Fail',
  GetFarmCountSuccess = '[Farm] Get Farm Count Success',
  GetFarmCredits = '[Farm] Get Farm Credits',
  GetFarmCreditsFail = '[Farm] Get Farm Credits Fail',
  GetFarmCreditsSuccess = '[Farm] Get Farm Credits Success',
  IncludeFarmPropertiesMarkers = '[Farm] Include Viewed Farm Markers',
  LoadFarm = '[Farm] Load Farm',
  ResetFarmAlerts = '[Farm] Reset Farm Alerts',
  ResetFarmCountSuccess = '[Farm] Reset Farm Count Success',
  ResetSelectedFarm = '[Farm] Reset Selected Farm',
  ResetViewedFarm = '[Farm] Reset Viewed Farm',
  UpdateFarm = '[Parse] Update Farm',
  UpdateFarmFail = '[Parse] Update Farm Fail',
  UpdateFarmSuccess = '[Parse] Update Farm Success',
  UpdateReadFarmAlertsByIds = '[Farm] Update Read Farm Alerts By Ids',
  UpdateSavedFarmProperties = '[Farm] Update Saved Farm Properties',
  UpdateViewedFarm = '[Farm] Update Viewed Farm',
  UpdateViewedFarmProperties = '[Farm] Update Viewed Farm Properties'
}

export class GetFarmCredits implements Action {
  readonly type = FarmActionTypes.GetFarmCredits;

  constructor() {}
}
export class GetFarmCreditsSuccess implements Action {
  readonly type = FarmActionTypes.GetFarmCreditsSuccess;

  constructor(public payload: number) {}
}
export class GetFarmCreditsFail implements Action {
  readonly type = FarmActionTypes.GetFarmCreditsFail;

  constructor(public payload: Error) {}
}

export class GetFarmCount implements Action {
  readonly type = FarmActionTypes.GetFarmCount;

  constructor(
    public payload: {
      countPayload: FarmCountFilters;
      rawFarmCoordinates: PolygonFarmCoordinates | RadiusFarmCoordinates;
    }
  ) {}
}
export class GetFarmCountSuccess implements Action {
  readonly type = FarmActionTypes.GetFarmCountSuccess;

  constructor(
    public payload: {
      farmCoordinates: PolygonFarmCoordinates;
      rawFarmCoordinates: PolygonFarmCoordinates | RadiusFarmCoordinates;
      turnOver: FarmTurnOver;
    }
  ) {}
}
export class GetFarmCountFail implements Action {
  readonly type = FarmActionTypes.GetFarmCountFail;

  constructor(public payload: Error) {}
}
export class ResetFarmCountSuccess implements Action {
  readonly type = FarmActionTypes.ResetFarmCountSuccess;

  constructor() {}
}

export class ResetSelectedFarm implements Action {
  readonly type = FarmActionTypes.ResetSelectedFarm;

  constructor() {}
}

export class ResetViewedFarm implements Action {
  readonly type = FarmActionTypes.ResetViewedFarm;

  constructor() {}
}
export class UpdateReadFarmAlertsByIds implements Action {
  readonly type = FarmActionTypes.UpdateReadFarmAlertsByIds;

  constructor(public payload: string[]) {}
}
export class ResetFarmAlerts implements Action {
  readonly type = FarmActionTypes.ResetFarmAlerts;

  constructor() {}
}

export class FilterViewedFarm implements Action {
  readonly type = FarmActionTypes.FilterViewedFarm;

  constructor(
    public payload: {
      farmId?: string;
      resetFilters?: boolean;
    }
  ) {}
}

export class UpdateViewedFarm implements Action {
  readonly type = FarmActionTypes.UpdateViewedFarm;

  constructor(
    public payload: {
      data: Partial<FarmData>;
    }
  ) {}
}
export class UpdateViewedFarmProperties implements Action {
  readonly type = FarmActionTypes.UpdateViewedFarmProperties;

  constructor(
    public payload: {
      isSelectiveUpdate: boolean;
      properties: PropertyData[];
      propertyCount?: number;
    }
  ) {}
}

export class IncludeFarmPropertiesMarkers implements Action {
  readonly type = FarmActionTypes.IncludeFarmPropertiesMarkers;

  constructor(public payload: PfLocation[]) {}
}

export class UpdateSavedFarmProperties implements Action {
  readonly type = FarmActionTypes.UpdateSavedFarmProperties;

  constructor(public payload: { changes: { properties: PropertyData[] }; id: string; savedFarm?: FarmData }) {}
}

export class ClearAllSavedFarms implements Action {
  readonly type = FarmActionTypes.ClearAllSavedFarms;

  constructor() {}
}

export class LoadFarm implements Action {
  readonly type = FarmActionTypes.LoadFarm;

  constructor() {}
}

export class UpdateFarm implements Action {
  readonly type = FarmActionTypes.UpdateFarm;

  constructor(public payload: { data: Partial<FarmData> }) {}
}
export class UpdateFarmSuccess implements Action {
  readonly type = FarmActionTypes.UpdateFarmSuccess;

  constructor(public payload: { changes: Partial<FarmData>; id: string }) {}
}
export class UpdateFarmFail implements Action {
  readonly type = FarmActionTypes.UpdateFarmFail;

  constructor(public payload: Error) {}
}

export type FarmActions =
  | GetFarmCredits
  | GetFarmCreditsSuccess
  | GetFarmCreditsFail
  | GetFarmCount
  | GetFarmCountSuccess
  | GetFarmCountFail
  | ResetSelectedFarm
  | ClearAllSavedFarms
  | ResetViewedFarm
  | UpdateReadFarmAlertsByIds
  | ResetFarmAlerts
  | FilterViewedFarm
  | UpdateViewedFarm
  | UpdateViewedFarmProperties
  | UpdateSavedFarmProperties
  | IncludeFarmPropertiesMarkers
  | ResetFarmCountSuccess
  | LoadFarm
  | UpdateFarm
  | UpdateFarmSuccess
  | UpdateFarmFail;
