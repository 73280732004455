import { AfterViewInit, Directive, Input, ViewContainerRef } from '@angular/core';
import { AbstractControl, FormGroupDirective } from '@angular/forms';

import { FormItem } from '../../typings/form';

import { FormErrorMessagesComponent } from '../components/form/form-error-messages/form-error-messages.component';

enum ControlStatus {
  Invalid = 'INVALID',
  Valid = 'VALID'
}

@Directive({
  selector: '[smdFormErrorMessage]'
})
export class FormErrorMessageDirective implements AfterViewInit {
  @Input('smdFormErrorMessage') fieldData: FormItem;

  private fieldControl: AbstractControl;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private formGroupDirective: FormGroupDirective
  ) {}

  ngAfterViewInit() {
    this.fieldControl = this.formGroupDirective.form?.controls[this.fieldData?.name];

    if (this.fieldControl) {
      this.fieldControl.statusChanges.subscribe((formControlStatus) => {
        this.statusChanged(formControlStatus);
      });
    }
  }

  private statusChanged(formControlStatus: string) {
    switch (formControlStatus) {
      case ControlStatus.Invalid:
        this.viewContainerRef.clear();
        this.viewContainerRef.createComponent(FormErrorMessagesComponent).instance.fieldData = {
          ...this.fieldData,
          fieldControl: this.fieldControl
        };
        break;

      case ControlStatus.Valid:
      default:
        this.viewContainerRef.clear();
        break;
    }
  }
}
