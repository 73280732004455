import { Component, ElementRef, HostListener, NgZone, OnInit, ViewChild } from '@angular/core';
import { Platform } from '@ionic/angular';
import { createGesture } from '@ionic/core';

import { EmailComposer } from '@awesome-cordova-plugins/email-composer/ngx';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, filter, throttleTime } from 'rxjs/operators';

import { AppSettingsSalesInfo } from '../../../typings/app';

import { GenericComponent } from '../generic/generic.component';

import * as fromAppConfig from '../../../store/selectors/app-config/index';
import * as fromAppSettings from '../../../store/selectors/app-settings/index';

import { DomManipulationProvider } from '../../../providers/dom-manipulation.service';
import { ContactsService } from '../../services/contacts/contacts.service';
import { KeyboardService } from '../../services/keyboard/keyboard.service';
import { LayoutService } from '../../services/layout/layout.service';
import { PlatformDetectService } from '../../services/platform-detect/platform-detect.service';

const agentPhotoUrl = '/assets/img/default-contact-card-image.svg';

@Component({
  selector: 'smd-contact-card',
  styleUrls: ['./contact-card.component.scss'],
  templateUrl: './contact-card.component.html'
})
export class ContactCardComponent extends GenericComponent implements OnInit {
  isIPad = false;

  isMobilePlatform = true;
  isMobileView = true;
  agentPhotoUrl = agentPhotoUrl;
  copyright = new Date().getFullYear();
  isMobileKeyboardVisible = false;
  pageHasTabs = false;
  cardInfo: AppSettingsSalesInfo;
  expand = false;
  isContactSaveLoading = false;

  @ViewChild('desktopMailTo') desktopMailTo;

  constructor(
    private ngZone: NgZone,
    private platform: Platform,
    private elementRef: ElementRef,
    private emailComposer: EmailComposer,
    private store: Store<fromAppConfig.State | fromAppSettings.State>,
    private domService: DomManipulationProvider,
    private platformDetectService: PlatformDetectService,
    private keyboardService: KeyboardService,
    private layoutService: LayoutService,
    private contactsService: ContactsService
  ) {
    super();

    this.isIPad = this.platform.is('ipad');
  }

  @HostListener('window:keyboardDidShow', ['keyboardDidShow'])
  onKeyboardDidShow() {
    this.expand = false;
    this.layoutService.contactCardToggleHandler.next(this.expand);
    this.domService.removeScrollContentBottomMargin();
  }

  @HostListener('window:keyboardDidHide', ['keyboardDidHide'])
  onKeyboardDidHide() {
    this.domService.resetScrollContentBottomMargin();
  }

  ngOnInit() {
    this.addUniqueSubscription(
      'isMobileViewSubscription',
      this.layoutService.getMobileViewFlag().subscribe((isMobileView) => {
        this.ngZone.run(() => {
          this.isMobileView = isMobileView;
        });
      })
    );

    this.addUniqueSubscription(
      'isMobilePlatformSubscription',
      this.platformDetectService.getIsMobileSubscription().subscribe((isMobilePlatform) => {
        this.ngZone.run(() => {
          this.isMobilePlatform = isMobilePlatform;
        });
      })
    );

    this.addUniqueSubscription(
      'keyboardSettingsSubscription',
      this.keyboardService.getHandlerSettings().subscribe((keyboardSettings) => {
        if (this.isMobilePlatform || this.isMobileView) {
          this.ngZone.run(() => {
            this.isMobileKeyboardVisible = !!keyboardSettings?.isVisible;
          });
        }
      })
    );

    this.addUniqueSubscription(
      'appSettingsSalesInfoSubscription',
      this.store
        .select(fromAppSettings.getAppSettingsSalesInfo)
        .pipe(filter((cardInfo) => !!cardInfo))
        .subscribe((cardInfo) => {
          this.ngZone.run(() => {
            this.cardInfo = cardInfo;
            this.agentPhotoUrl = cardInfo.agentPhotoUrl ?? agentPhotoUrl;
          });
        })
    );

    this.addUniqueSubscription(
      'tabsDisplaySubscription',
      this.layoutService
        .getNavTabsState()
        .pipe(distinctUntilChanged(), throttleTime(300, undefined, { leading: true, trailing: true }))
        .subscribe((state) => {
          this.ngZone.run(() => {
            this.pageHasTabs = state.isVisible;
          });
        })
    );

    this.handleExpandToggle();
  }

  /**
   * Hammer Direction codes
   * DIRECTION_NONE         1
   * DIRECTION_LEFT         2
   * DIRECTION_RIGHT        4
   * DIRECTION_UP           8
   * DIRECTION_DOWN         16
   * DIRECTION_HORIZONTAL   6
   * DIRECTION_VERTICAL     24
   * DIRECTION_ALL          30
   */
  swipeEvent(event) {
    if (!this.isMobilePlatform) {
      return;
    }

    this.ngZone.run(() => {
      if (event.direction === 8) {
        this.expand = true;
        this.layoutService.contactCardToggleHandler.next(true);
      }

      if (event.direction === 16) {
        this.expand = false;
        this.layoutService.contactCardToggleHandler.next(true);
      }
    });
  }

  handleExpandToggle() {
    if (!(this.isMobilePlatform || this.isMobileView)) {
      return;
    }

    const DOUBLE_CLICK_THRESHOLD = 500;
    let lastOnStart = 0;

    const gesture = createGesture({
      el: this.elementRef.nativeElement,
      gestureName: 'double-click',
      onStart: () => {
        this.ngZone.run(() => {
          const now = Date.now();

          if (Math.abs(now - lastOnStart) <= DOUBLE_CLICK_THRESHOLD) {
            this.expand = !this.expand;
            this.layoutService.contactCardToggleHandler.next(this.expand);
            lastOnStart = 0;
          } else {
            lastOnStart = now;
          }
        });
      },
      threshold: 0
    });

    gesture.enable();
  }

  onMailTo(email: string) {
    const emailOptions = {
      app: 'mailto',
      to: email
    };

    if (this.isMobilePlatform) {
      this.emailComposer.open(emailOptions);
    } else if (this.desktopMailTo) {
      this.desktopMailTo.nativeElement.href = `mailto:${emailOptions.to}`;
    }
  }

  async onContactAdd() {
    this.ngZone.run(() => {
      this.isContactSaveLoading = true;
    });

    await this.contactsService.addContact({
      emails: [
        {
          type: 'work',
          value: this.cardInfo.email
        }
      ],
      familyName: this.cardInfo.lastName,
      firstName: this.cardInfo.firstName,
      phoneNumbers: [
        {
          type: 'mobile',
          value: this.cardInfo.phone
        }
      ]
    });

    this.ngZone.run(() => {
      this.isContactSaveLoading = false;
    });
  }
}
