import { MobileUserGetAttributes } from '../../../typings/mobile-user';

import * as userProfileActions from '../../actions/app-settings/user-profile';
import * as parseImplementationActions from '../../actions/parse/parse-implementation';
import * as appParseActions from '../../actions/parse/parse-implementation';

export interface State {
  changedPassword: boolean;
  error: Error;
  expiredPassword: boolean;
  googleAnalytics: boolean;
  pending: boolean;
  succeeded: boolean;
  user: MobileUserGetAttributes | null;
}

export const initialState: State = {
  changedPassword: false,
  error: null,
  expiredPassword: false,
  googleAnalytics: false,
  pending: false,
  succeeded: false,
  user: null
};

export function reducer(
  state = initialState,
  action: userProfileActions.UserProfileAction | parseImplementationActions.ParseActions
) {
  switch (action.type) {
    case userProfileActions.UserProfileActionTypes.SetAppUserData: {
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload
        }
      };
    }

    case userProfileActions.UserProfileActionTypes.ResetPassword: {
      return {
        ...state,
        changedPassword: false,
        error: null,
        pending: true,
        succeeded: false
      };
    }

    case userProfileActions.UserProfileActionTypes.ResetPasswordSuccess: {
      return {
        ...state,
        changedPassword: true,
        error: null,
        pending: false,
        succeeded: true
      };
    }

    case userProfileActions.UserProfileActionTypes.ResetPasswordFail: {
      return {
        ...state,
        changedPassword: false,
        error: action.payload,
        pending: false,
        succeeded: false
      };
    }

    case userProfileActions.UserProfileActionTypes.VerifyUserSuccess: {
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload
        }
      };
    }
    case userProfileActions.UserProfileActionTypes.VerifyUserFail: {
      return {
        ...state,
        error: action.payload,
        pending: false,
        succeeded: false
      };
    }
    case userProfileActions.UserProfileActionTypes.AuthenticateUser: {
      return {
        ...state,
        error: null,
        pending: true,
        succeeded: false
      };
    }
    case userProfileActions.UserProfileActionTypes.AuthenticateUserSuccess: {
      return {
        ...state,
        error: null,
        pending: false,
        succeeded: true
      };
    }
    case userProfileActions.UserProfileActionTypes.AuthenticateUserFail: {
      return {
        ...state,
        error: action.payload,
        pending: false,
        succeeded: false
      };
    }

    case userProfileActions.UserProfileActionTypes.ChangeExpiredPassword: {
      return {
        ...state,
        changedPassword: false,
        error: null,
        expiredPassword: action.payload,
        pending: false,
        succeeded: false
      };
    }

    case userProfileActions.UserProfileActionTypes.UpdateUserFromInternalStorage: {
      return {
        ...state,
        error: null,
        pending: false,
        succeeded: false,
        user: action.payload.user || state.user
      };
    }

    case userProfileActions.UserProfileActionTypes.ResetUserProfile: {
      return {
        ...state,
        error: null,
        googleAnalytics: false,
        pending: false,
        succeeded: false,
        user: {
          ...initialState.user
        }
      };
    }

    case userProfileActions.UserProfileActionTypes.ResetUserProfileErrors: {
      return {
        ...state,
        error: null,
        pending: false,
        succeeded: false
      };
    }

    case parseImplementationActions.ParseActionTypes.UpdateUserSuccess: {
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload
        }
      };
    }

    case parseImplementationActions.ParseActionTypes.UpdateUserFarmAlertSettingsSuccess:
    case parseImplementationActions.ParseActionTypes.GetFarmAlertSettingsSuccess: {
      return {
        ...state,
        user: {
          ...state.user,
          autoSubscribeFarmAlerts: action.payload.autoSubscribeFarmAlerts ?? !!state.user?.autoSubscribeFarmAlerts,
          farmAlertEmailNotifications:
            action.payload.farmAlertEmailNotifications ?? !!state.user?.farmAlertEmailNotifications,
          farmAlertPushNotifications:
            action.payload.farmAlertPushNotifications ?? !!state.user?.farmAlertPushNotifications
        }
      };
    }

    case appParseActions.ParseActionTypes.GetRegisteredUserSuccess:
    case appParseActions.ParseActionTypes.CreateUserSuccess: {
      return {
        ...state,
        error: null,
        pending: false,
        succeeded: true,
        user: {
          ...state.user,
          id: action.payload.id
        }
      };
    }

    default: {
      return state;
    }
  }
}

export const getIfPasswordIsExpired = (state: State) => state.expiredPassword;

export const getError = (state: State) => state.error;

export const getUserData = (state: State) => state.user;
export const getBadgerBucks = (state: State) => state.user?.badgerBucks || 0;

export const getFarmAlertSettings = (state: State) => ({
  autoSubscribeFarmAlerts: !!state.user?.autoSubscribeFarmAlerts,
  farmAlertEmailNotifications: !!state.user?.farmAlertEmailNotifications,
  farmAlertPushNotifications: !!state.user?.farmAlertPushNotifications
});
export const getFarmAlertSettingsAutoSubscribe = (state: State) => !!state.user?.autoSubscribeFarmAlerts;
