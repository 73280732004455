import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { compact, has, omitBy } from 'lodash-es';
import { from as fromPromise, of } from 'rxjs';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { ADMIN_PORTAL_CONFIG } from '../../../environments/environment';
import { adminPortalEndpoints } from '../../constants/admin-portal';
import { PfLocation } from '../../typings/app';
import { AdminPortalLocationCounty } from '../../typings/location';
import { SearchAddress } from '../../typings/map';
import { PropertyProfileData } from '../../typings/property-profile';

import * as mapConfigAction from '../../store/actions/app-config/map-data';

import { ApiProvider } from '../../providers/api.service';
import { GeocodeService } from '../../providers/geocode-helper.service';
import { MapUtils } from '../../providers/map-utils/map-utils.service';
import { PfHelperService } from '../../providers/pf-helper-service.service';
import { ApiService } from '../../common/services/api/api.service';

import * as parseAction from '../actions/parse/parse-implementation';
import * as propertyProfileAction from '../actions/property-data/property-profile';
import * as propertySearchAction from '../actions/property-search/search-types';
import * as walkingFarmAction from '../actions/walking-farm/fast-farm';
import * as fromAppConfig from '../selectors/app-config';
import * as fromPropertyData from '../selectors/property-data';
import * as fromPropertySearch from '../selectors/property-search';

@Injectable()
export class PropertyProfileEffects {
  private zoomLevel = 19;

  getFromSearchByAddress$ = createEffect(() =>
    this.actions$
      .pipe(ofType<propertySearchAction.SearchByAddress>(propertySearchAction.SearchTypesActionTypes.SearchByAddress))
      .pipe(
        withLatestFrom(
          this.store.select(fromAppConfig.getRepDataProviderId),
          this.store.select(fromAppConfig.getPropertyKey),
          this.store.select(fromAppConfig.getGeneralInfoDeviceData)
        ),
        switchMap(([action, providerId, propertyKey, deviceData]) =>
          this.oldApiService
            .getData(
              'FindByAddress',
              {
                address: action.payload.address,
                deviceData: this.pfHelperService.getComputeDeviceData(deviceData),
                key: propertyKey,
                zip: action.payload.zip
              },
              'PP-KEY-REFRESH',
              providerId
            )
            .pipe(
              switchMap((result) => this.processSearchResults(result, action.payload.location)),
              catchError((error) => {
                if (error.message) {
                  this.pfHelperService.showSystemError(error.message, () =>
                    this.store.dispatch(new propertySearchAction.ResetSearchError())
                  );
                }

                return of(new propertySearchAction.GetSearchResultsFail(error));
              })
            )
        )
      )
  );

  updateMapAfterSearch = createEffect(() =>
    this.actions$
      .pipe(
        ofType<propertySearchAction.UpdateMapAfterSearch>(
          propertySearchAction.SearchTypesActionTypes.UpdateMapAfterSearch
        )
      )
      .pipe(
        switchMap((action) => {
          let dispatchActions: Action[] = [new mapConfigAction.ChangeZoomValue(this.zoomLevel)];

          const actions = (markers: PfLocation[]) => {
            const formattedMarkers = markers.map((marker) => {
              let result = marker;

              if (marker.icon) {
                result = {
                  ...marker,
                  icon: {
                    ...marker.icon,
                    url: `/assets/svg/map-markers/${marker.icon.url}`
                  }
                };
              }

              return result;
            });

            return [
              new mapConfigAction.UpdateLocation({
                lat: formattedMarkers[0].latitude,
                lng: formattedMarkers[0].longitude
              }),
              new propertySearchAction.SetMarkers(formattedMarkers),
              new propertySearchAction.GetSearchResultsSuccess(action.payload.results)
            ];
          };

          if (action.payload.location) {
            dispatchActions = dispatchActions.concat(actions([action.payload.location]));
          } else {
            const distinctAddresses = action.payload.results
              .slice(0, 11)
              .filter(
                (item, index, self) => index === self.findIndex((t) => t.address === item.address && t.zip === item.zip)
              );

            if (distinctAddresses.length) {
              dispatchActions.push(
                new propertySearchAction.GetSingleAddressMarker({
                  address: distinctAddresses,
                  onSuccess: (markers) => {
                    return actions(markers);
                  }
                })
              );
            } else {
              this.displayAlert('No properties found for your search.');
            }
          }

          return dispatchActions;
        })
      )
  );

  geocodeSingleAddress$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType<propertySearchAction.GetSingleAddressMarker>(
          propertySearchAction.SearchTypesActionTypes.GetSingleAddressMarker
        )
      )
      .pipe(
        switchMap((action) =>
          fromPromise(this.mapUtils.validateLocation(action.payload.address)).pipe(
            filter((location) => !!(location?.latitude && location?.longitude)),
            switchMap((location) => action.payload.onSuccess([location])),
            catchError((error: string) => {
              this.pfHelperService.showSystemError(error);
              return of(new propertySearchAction.GetSingleAddressMarkerFail(error));
            })
          )
        )
      )
  );

  geocodeAddresses$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType<propertyProfileAction.GeocodeAddresses>(
          propertyProfileAction.PropertyProfileActionTypes.GeocodeAddresses
        )
      )
      .pipe(
        switchMap((action) =>
          this.geocoderService
            .find(action.payload.addresses)
            .markers()
            .pipe(
              filter((markers) => !!markers),
              switchMap((markers) => {
                this.geocoderService.reset();
                return action.payload.onSuccess(markers);
              }),
              catchError((error) => {
                this.geocoderService.reset();
                throw new Error(error);
              })
            )
        )
      )
  );

  getFromSearchByOwner$ = createEffect(() =>
    this.actions$
      .pipe(ofType<propertySearchAction.SearchByOwner>(propertySearchAction.SearchTypesActionTypes.SearchByOwner))
      .pipe(
        withLatestFrom(
          this.store.select(fromAppConfig.getRepDataProviderId),
          this.store.select(fromAppConfig.getPropertyKey),
          this.store.select(fromAppConfig.getGeneralInfoDeviceData)
        ),
        switchMap(([action, providerId, propertyKey, deviceData]) =>
          this.oldApiService
            .getData(
              'FindByOwner',
              {
                ...omitBy(action.payload, (value) => !value),
                deviceData: this.pfHelperService.getComputeDeviceData(deviceData),
                key: propertyKey
              },
              'PP-KEY-REFRESH',
              providerId
            )
            .pipe(
              switchMap((result) => this.processSearchResults(result)),
              catchError((error) => {
                if (error.message) {
                  this.pfHelperService.showSystemError(error.message, () =>
                    this.store.dispatch(new propertySearchAction.ResetSearchError())
                  );
                }

                return of(new propertySearchAction.GetSearchResultsFail(error));
              })
            )
        )
      )
  );

  getFromSearchByParcelNumber$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType<propertySearchAction.SearchByParcelNumber>(
          propertySearchAction.SearchTypesActionTypes.SearchByParcelNumber
        )
      )
      .pipe(
        withLatestFrom(
          this.store.select(fromAppConfig.getRepDataProviderId),
          this.store.select(fromAppConfig.getPropertyKey),
          this.store.select(fromAppConfig.getGeneralInfoDeviceData)
        ),
        switchMap(([action, providerId, propertyKey, deviceData]) => {
          return this.oldApiService
            .getData(
              'FindByAPN',
              {
                ...action.payload,
                deviceData: this.pfHelperService.getComputeDeviceData(deviceData),
                key: propertyKey
              },
              'PP-KEY-REFRESH',
              providerId
            )
            .pipe(
              switchMap((result) => this.processSearchResults(result)),
              catchError((error) => {
                if (error.message) {
                  this.pfHelperService.showSystemError(error.message, () =>
                    this.store.dispatch(new propertySearchAction.ResetSearchError())
                  );
                }

                return of(new propertySearchAction.GetSearchResultsFail(error));
              })
            );
        })
      )
  );

  getProfileWithoutResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType<propertyProfileAction.GetPropertyProfileDataWithoutResults>(
        propertyProfileAction.PropertyProfileActionTypes.GetPropertyProfileDataWithoutResults
      ),
      withLatestFrom(
        this.store.select(fromAppConfig.getRepDataProviderId),
        this.store.select(fromAppConfig.getPropertyKey),
        this.store.select(fromAppConfig.getGeneralInfoDeviceData)
      ),
      switchMap(([action, providerId, propertyKey, deviceData]) => {
        return this.oldApiService
          .getData(
            'FindByAPN',
            {
              apn: action.payload.data.apn,
              county: action.payload.data.county,
              deviceData: this.pfHelperService.getComputeDeviceData(deviceData),
              key: propertyKey,
              state: action.payload.data.state
            },
            'PP-KEY-REFRESH',
            providerId
          )
          .pipe(
            switchMap((result: { d: PropertyProfileData }) => [
              new propertyProfileAction.GetPropertyProfileDataWithResults({
                data: result,
                savePropertyProfile: !!action.payload.savePropertyProfile
              }),
              new parseAction.SavePropertyProfile(result)
            ])
          );
      })
    )
  );

  getProfileWithResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType<propertyProfileAction.GetPropertyProfileDataWithResults>(
        propertyProfileAction.PropertyProfileActionTypes.GetPropertyProfileDataWithResults
      ),
      withLatestFrom(this.store.select(fromAppConfig.getRepDataProviderId)),
      switchMap(([action, providerId]) => {
        this.changeZoomLevel();

        const actions: Action[] = [
          new propertyProfileAction.GetPropertyProfileDataSuccess({
            propertyReport: action.payload.data.d,
            providerId
          }),
          new walkingFarmAction.GetMapMarker({
            latitude: parseFloat(action.payload.data.d.latitude),
            longitude: parseFloat(action.payload.data.d.longitude)
          })
        ];

        if (action.payload.savePropertyProfile) {
          actions.unshift(new parseAction.SavePropertyProfile(action.payload.data));
        }

        return actions;
      })
    )
  );

  getProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType<propertyProfileAction.GetPropertyProfileData>(
        propertyProfileAction.PropertyProfileActionTypes.GetPropertyProfileData
      ),
      withLatestFrom(this.store.select(fromPropertySearch.getPropertySearchResultRawData)),
      switchMap(([action, searchResult]) => {
        let result: Action = new propertyProfileAction.GetPropertyProfileDataWithoutResults(action.payload);

        if (has(searchResult, 'd.APN') && searchResult?.d.APN === action.payload.data.apn) {
          result = new propertyProfileAction.GetPropertyProfileDataWithResults({
            data: searchResult,
            savePropertyProfile: !!action.payload.savePropertyProfile
          });
        }

        return of(result);
      })
    )
  );

  getComparableSalesPropertyProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType<propertyProfileAction.GetComparableSalesPropertyProfileData>(
        propertyProfileAction.PropertyProfileActionTypes.GetComparableSalesPropertyProfileData
      ),
      withLatestFrom(
        this.store.select(fromAppConfig.getRepDataProviderId),
        this.store.select(fromAppConfig.getPropertyKey),
        this.store.select(fromAppConfig.getGeneralInfoDeviceData)
      ),
      switchMap(([action, providerId, propertyKey, deviceData]) => {
        return this.oldApiService
          .getData(
            'FindByAddress',
            {
              address: action.payload.address,
              deviceData: this.pfHelperService.getComputeDeviceData(deviceData),
              key: propertyKey,
              zip: action.payload.zip
            },
            'PP-KEY-REFRESH',
            providerId
          )
          .pipe(
            switchMap((result: { d: PropertyProfileData }) => [
              new mapConfigAction.ChangeZoomValue(this.zoomLevel),
              new parseAction.SavePropertyProfile(result),
              new propertyProfileAction.GetPropertyProfileDataSuccess({
                propertyReport: result.d,
                providerId
              }),
              new walkingFarmAction.GetMapMarker({
                latitude: parseFloat(result.d.latitude),
                longitude: parseFloat(result.d.longitude)
              })
            ]),
            catchError((error) => {
              this.pfHelperService.showSystemError(error.message, () =>
                this.store.dispatch(new propertySearchAction.ResetSearchError())
              );
              return of(new propertySearchAction.GetSearchResultsFail(error));
            })
          );
      })
    )
  );

  getPlatMap$ = createEffect(() =>
    this.actions$
      .pipe(ofType<propertyProfileAction.GetPlatMap>(propertyProfileAction.PropertyProfileActionTypes.GetPlatMap))
      .pipe(
        withLatestFrom(
          this.store.select(fromAppConfig.getRepDataProviderId),
          this.store.select(fromAppConfig.getPropertyKey),
          this.store.select(fromAppConfig.getGeneralInfoDeviceData)
        ),
        switchMap(([action, providerId, propertyKey, deviceData]) => {
          return this.oldApiService
            .getData(
              'GetMapDT',
              {
                ...action.payload,
                deviceData: this.pfHelperService.getComputeDeviceData(deviceData),
                key: propertyKey
              },
              'PP-KEY-REFRESH',
              providerId
            )
            .pipe(
              map(() => {
                this.pfHelperService.showSystemError('You should receive your plat map shortly.', null, 'Plat Map');
                return new propertyProfileAction.GetPlatMapSuccess();
              }),
              catchError((error) => {
                this.pfHelperService.showSystemError('A plat map is not available for this property address.');
                return of(new propertyProfileAction.GetPlatMapFail(error));
              })
            );
        })
      )
  );

  getStateCounties = createEffect(() =>
    this.actions$
      .pipe(ofType<propertySearchAction.GetCountiesList>(propertySearchAction.SearchTypesActionTypes.GetCountiesList))
      .pipe(
        withLatestFrom(this.store.select(fromAppConfig.getAppInfoParseUrlType)),
        switchMap(([action, parseUrlType]) =>
          this.apiService
            .sendRequest({
              method: 'GET',
              requestOptions: {
                params: {
                  parseType: parseUrlType
                }
              },
              url: `${ADMIN_PORTAL_CONFIG.apiUrl}/v1/${adminPortalEndpoints.locations}/State/${action.payload.selectedState}/County`
            })
            .pipe(
              map(
                (result: AdminPortalLocationCounty[]) =>
                  new propertySearchAction.SetCountiesList({
                    countiesList: result
                  })
              )
            )
        )
      )
  );

  private changeZoomLevel() {
    this.store.dispatch(new mapConfigAction.ChangeZoomValue(this.zoomLevel));
  }

  private processSearchResults(result, actionPayloadLocation = null) {
    let data: SearchAddress[] = [];

    const search = `${result.d.SiteAddress} ${result.d.SiteUnitType} ${result.d.SiteUnit}, ${result.d.SiteCity} ${result.d.SiteState} ${result.d.SiteZip}`;

    if (result.d.Locations?.length) {
      const locations = result.d.Locations[0].length > 1 ? result.d.Locations[0] : result.d.Locations;

      data = locations.map((location) => {
        const geocode = compact([
          compact([location.Address, location.UnitType, location.UnitNumber]).join(' '),
          location.City,
          compact([location.State, location.ZIP]).join(' ')
        ]).join(', ');

        return {
          address: location.Address,
          apn: location.APN,
          city: location.City,
          county: location.county,
          search: geocode,
          state: location.State,
          unit: location.UnitNumber,
          unitType: location.UnitType,
          zip: location.ZIP
        };
      });
    } else if (search.replace(/,/g, '').trim()) {
      data.push({
        address: result.d.SiteAddress,
        apn: result.d.APN,
        city: result.d.SiteCity,
        search,
        state: result.d.SiteState,
        unit: result.d.SiteUnit,
        unitType: result.d.SiteUnitType,
        zip: result.d.SiteZip
      });

      this.store.dispatch(new propertySearchAction.SetSearchResultRawData(result));
      this.store.dispatch(new parseAction.SavePropertyProfile(result));
    }

    return of(
      new propertySearchAction.UpdateMapAfterSearch({
        location: actionPayloadLocation,
        results: data.filter((item) => item.address)
      })
    );
  }

  private async displayAlert(message: string) {
    const alert = await this.alertController.create({
      buttons: [
        {
          role: 'cancel',
          text: 'OK'
        }
      ],
      message
    });

    await alert.present();
  }

  constructor(
    private store: Store<fromPropertyData.State | fromPropertySearch.State>,
    private actions$: Actions,
    private alertController: AlertController,
    private oldApiService: ApiProvider,
    private pfHelperService: PfHelperService,
    private geocoderService: GeocodeService,
    private mapUtils: MapUtils,
    private apiService: ApiService
  ) {}
}
