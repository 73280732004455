import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { AlertController, NavController, Platform } from '@ionic/angular';

import { Store } from '@ngrx/store';
import { isNil } from 'lodash-es';
import { combineLatest } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { GenericComponent } from './common/components/generic/generic.component';

import * as userProfileActions from './store/actions/app-settings/user-profile';
import * as parseActions from './store/actions/parse/parse-implementation';
import * as propertySearchAction from './store/actions/property-search/search-types';
import * as fromAppConfig from './store/selectors/app-config';
import * as fromAppSettings from './store/selectors/app-settings';

import { AppService } from './app.service';
import { LayoutService } from './common/services/layout/layout.service';
import { PlatformDetectService } from './common/services/platform-detect/platform-detect.service';

@Component({
  selector: 'smd-root',
  templateUrl: 'app.component.html'
})
export class AppComponent extends GenericComponent implements OnInit, OnDestroy {
  private isMobilePlatform = true;

  isMobileView = true;

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (!this.isMobilePlatform) {
      this.platform.ready().then(() => {
        this.layoutService.setMobileViewFlag(this.platform.width() < 992);
      });
    }
  }

  @HostListener('document:deviceready', ['deviceready'])
  onDeviceReady() {
    this.platform.ready().then(() => {
      if (this.platform.is('mobileweb')) {
        this.navController.navigateRoot(['/', 'app-store']);
      }
    });
  }

  constructor(
    private alertController: AlertController,
    private appService: AppService,
    private layoutService: LayoutService,
    private navController: NavController,
    private platform: Platform,
    private platformDetectService: PlatformDetectService,
    private store: Store
  ) {
    super();

    this.platform.ready().then(() => {
      this.layoutService.setMobileViewFlag(this.platform.width() < 992);
    });

    this.platformDetectService.getIsMobileSubscription().subscribe((isMobilePlatform) => {
      this.isMobilePlatform = isMobilePlatform;
    });
  }

  ngOnInit() {
    this.addUniqueSubscription(
      'isAuthenticatedSubscription',
      this.store
        .select(fromAppConfig.getIsAuthenticated)
        .pipe(filter((isAuthenticated) => isAuthenticated))
        .subscribe(async () => {
          await this.appService.setupNotifications();
          await this.appService.handleNotifications();
        })
    );

    this.addUniqueSubscription(
      'appInfoFarmAlertsEnabledSubscription',
      combineLatest([
        this.store.select(fromAppConfig.shouldGetFarmAlertSettings),
        this.store.select(fromAppSettings.getAppSettingsUserProfileData)
      ])
        .pipe(
          filter(([shouldGetFarmAlertSettings, user]) => shouldGetFarmAlertSettings && !isNil(user)),
          take(1)
        )
        .subscribe(() => {
          this.store.dispatch(new parseActions.GetFarmAlertSettings());
        })
    );

    this.addUniqueSubscription(
      'newPropertyKeyTermsToAcceptSubscription',
      this.store
        .select(fromAppConfig.hasNewPropertyTermsToAccept)
        .pipe(filter((hasNewPropertyTermsToAccept) => hasNewPropertyTermsToAccept))
        .subscribe(() => {
          this.navController.navigateRoot(['/', 'new-terms-of-service']);
        })
    );

    this.addUniqueSubscription(
      'newFarmKeyTermsToAcceptSubscription',
      this.store
        .select(fromAppConfig.hasNewFarmTermsToAccept)
        .pipe(filter((hasNewFarmTermsToAccept) => hasNewFarmTermsToAccept))
        .subscribe(() => {
          this.navController.navigateRoot(['/', 'new-terms-of-service']);
        })
    );

    this.addUniqueSubscription(
      'passwordExpiredSubscription',
      this.store
        .select(fromAppSettings.shouldChangePassword)
        .pipe(filter((shouldChangePassword) => shouldChangePassword))
        .subscribe(async () => {
          await this.showChangePasswordDialog();
        })
    );
  }

  private async showChangePasswordDialog() {
    const alert = await this.alertController.create({
      backdropDismiss: false,
      buttons: [
        {
          handler: () => this.openChangePasswordPage(),
          text: 'Change Password'
        },
        {
          handler: () => this.cancelPasswordChange(),
          text: 'Cancel'
        }
      ],
      header: 'Login Error',
      message: 'Password has expired'
    });

    await alert.present();
  }

  private async cancelPasswordChange() {
    const alert = await this.alertController.create({
      backdropDismiss: false,
      buttons: [
        {
          handler: () => this.dismissOkMessage(),
          text: 'OK'
        }
      ],
      header: 'ERROR!',
      message: `You won't be able to access property data until you update your password.`
    });

    await alert.present();
  }

  private openChangePasswordPage() {
    this.navController.navigateRoot(['/', 'reset-password']);
  }

  private dismissOkMessage() {
    this.store.dispatch(new userProfileActions.ChangeExpiredPassword(false));
    this.store.dispatch(new propertySearchAction.ResetSearchError());
    this.store.dispatch(new propertySearchAction.ResetSearchTypes());
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
