import { capitalize, pick } from 'lodash-es';

import { REP_DATA_RESPONSE_PROVIDER_ID_LPS } from '../../../../constants/main.constants';
import { PfLocation } from '../../../typings/app';
import {
  ComparableSales,
  Neighborhood,
  PlatMapData,
  PropertyProfileData,
  PropertyProfileExportData,
  RawTransactionHistory,
  SalesInformation
} from '../../../typings/property-profile';

import * as parseActions from '../../actions/parse/parse-implementation';
import * as propertyDataActions from '../../actions/property-data/property-profile';
import * as propertySearchAction from '../../actions/property-search/search-types';

export interface State {
  additionalData: {
    address: string;
    fips: string;
    taxAmount: string;
    zip: string;
  };
  error: Error;
  exportPropertyProfileData: PropertyProfileExportData;
  pending: boolean;
  scrollPosition: number;
  succeeded: boolean;
  viewProfile: {
    data: {
      areaSales: {
        MedianNumBaths: string;
        MedianNumBeds: string;
        MedianValue: string;
      };
      propertyInfo: {
        APN: string;
        Baths: string;
        Bedrooms: string;
        PrimaryOwnerName: string;
        SalesPrice: string;
        reportDate: string;
        title: string;
      };
    } | null;
    detailsCharacteristics: {
      ownerInfo: Partial<PropertyProfileData>;
      propertyCharacteristics: Partial<PropertyProfileData>;
      propertyDetails: Partial<PropertyProfileData>;
    };
    platMap: PlatMapData;
    sales: {
      comparableSales: ComparableSales[] | null;
      compareWith: {
        search: string;
        shortAddress: string;
      } | null;
      markers: PfLocation[] | null;
      saleInfo: SalesInformation | null;
    };
    title: string;
    transactions: RawTransactionHistory[];
  };
}

export const initialState: State = {
  additionalData: {
    address: '',
    fips: '',
    taxAmount: '',
    zip: ''
  },
  error: null,
  exportPropertyProfileData: {} as PropertyProfileExportData,
  pending: false,
  scrollPosition: 0,
  succeeded: false,
  viewProfile: {
    data: null,
    detailsCharacteristics: {
      ownerInfo: {},
      propertyCharacteristics: {},
      propertyDetails: {}
    },
    platMap: {
      platMapData: '',
      platMapEncoding: '',
      platMapType: ''
    },
    sales: {
      comparableSales: null,
      compareWith: null,
      markers: null,
      saleInfo: null
    },
    title: '',
    transactions: []
  }
};

export function reducer(
  state = initialState,
  action:
    | propertyDataActions.PropertyProfileActions
    | parseActions.ParseActions
    | propertySearchAction.SearchTypesActions
) {
  switch (action.type) {
    case propertyDataActions.PropertyProfileActionTypes.GetPropertyProfileData:
    case parseActions.ParseActionTypes.GetPropertyProfile: {
      return {
        ...state,
        error: null,
        pending: true,
        succeeded: false
      };
    }

    case propertyDataActions.PropertyProfileActionTypes.GetPropertyProfileDataSuccess:
    case parseActions.ParseActionTypes.GetPropertyProfileSuccess: {
      const d: PropertyProfileData =
        action.type === parseActions.ParseActionTypes.GetPropertyProfileSuccess
          ? action.payload.propertyReport.reportContent['d']
          : action.payload.propertyReport;

      const providerId = action.payload.providerId;

      const title = [
        `${d.SiteAddress?.trim()} ${d.SiteUnitType?.trim()} ${d.SiteUnit?.trim()}`,
        `${d.SiteCity?.trim()} ${d.SiteState?.trim()} ${d.SiteZip?.trim()}`
      ].join(', ');

      const exportData = {
        data: {
          body: {
            report: {
              sections: [
                {
                  items: [
                    {
                      name: 'Property Owner Name',
                      value: d.PrimaryOwnerName
                    },
                    {
                      name: 'Secondary Owner Name',
                      value: d.SecondaryOwnerName
                    },
                    {
                      name: 'Address',
                      value: d.SiteAddress
                    },
                    {
                      name: 'Unit',
                      value: d.SiteUnit
                    },
                    {
                      name: 'Unit Type',
                      value: d.SiteUnitType
                    },
                    {
                      name: 'City',
                      value: d.SiteCity
                    },
                    {
                      name: 'State',
                      value: d.SiteState
                    },
                    {
                      name: 'Zip',
                      value: d.SiteZip
                    },
                    {
                      name: 'APN/TMS/PIN',
                      value: d.APN
                    },
                    {
                      name: 'Legal Brief Description',
                      value: d.LegalBriefDescription
                    },
                    {
                      name: 'CityMuniTwp',
                      value: d.CityMuniTwp
                    },
                    {
                      name: 'Mailing Address',
                      value: d.mailingAddress
                    },
                    {
                      name: 'Mailing Unit Number',
                      value: d.mailingUnit
                    },
                    {
                      name: 'Mailing City',
                      value: d.mailingCity
                    },
                    {
                      name: 'Mailing State',
                      value: d.mailingState
                    },
                    {
                      name: 'Mailing Zip',
                      value: d.mailingZip
                    }
                  ],
                  title: 'Property Details'
                },
                {
                  items: [
                    {
                      name: 'Bedrooms',
                      value: d.Bedrooms
                    },
                    {
                      name: 'Year Built',
                      value: d.YearBuilt
                    },
                    {
                      name: 'Building Area',
                      value: d.BuildingArea
                    },
                    {
                      name: 'Baths',
                      value: d.Baths
                    },
                    {
                      name: 'Garage Type',
                      value: d.GarageType
                    },
                    {
                      name: 'Garage Number of Cars',
                      value: d.GarageNumCars
                    },
                    {
                      name: 'Lot Size',
                      value: d.LotSize
                    },
                    {
                      name: 'Total Rooms',
                      value: d.TotalRooms
                    },
                    {
                      name: 'Fireplace',
                      value: d.Fireplace
                    },
                    {
                      name: 'Num Units',
                      value: d.NumUnits
                    },
                    {
                      name: 'Zoning',
                      value: d.Zoning
                    },
                    {
                      name: 'Pool',
                      value: d.Pool
                    },
                    {
                      name: 'Use Code',
                      value: d.UseCode
                    },
                    {
                      name: 'Basement',
                      value: d.Basement
                    }
                  ],
                  title: 'Property Characteristics'
                }
              ],
              title: `${d.SiteAddress} ${d.SiteCity}, ${d.SiteState} ${d.SiteZip}`
            }
          },
          client: {
            name: ''
          },
          footer: {
            copyright: `Copyright © Red Shed Technology Corp 2015-${new Date().getFullYear()}. All Rights Reserved.`,
            disclaimer: []
          },
          header: {
            agent: {
              company: '',
              email: '',
              name: '',
              phone: ''
            }
          }
        }
      };

      if (d.TransHistory) {
        d.TransHistory.forEach((t: RawTransactionHistory, index: number) => {
          exportData.data.body.report.sections.push({
            items: [
              {
                name: 'Transfer Date',
                value: d.TransferDate
              },
              {
                name: 'Seller Name',
                value: t.SellerName
              },
              {
                name: 'Sales Price',
                value: d.SalesPrice
              },
              {
                name: 'Document Number',
                value: t.DocumentNum
              },
              {
                name: 'Assessed Value',
                value: d.AssessedValue
              },
              {
                name: 'Market Value',
                value: d.MarketValue
              },
              {
                name: 'Recording Date',
                value: t.RecordingDate
              },
              {
                name: 'Document Type',
                value: t.DocumentType
              },
              {
                name: 'Price',
                value: t.Price
              },
              {
                name: 'Document No.',
                value: t.DocumentNum
              },
              {
                name: 'First TD',
                value: t.FirstTD
              },
              {
                name: 'Type of Sale',
                value: t.TypeOfSale
              },
              {
                name: 'Buyer Name',
                value: t.BuyerName
              },
              {
                name: 'Seller Name',
                value: t.SellerName
              }
            ],
            title: `Transaction History ${index + 1}`
          });
        });
      }

      if (d.AreaSales) {
        exportData.data.body.report.sections.push({
          items: [
            {
              name: 'Total Area Sales',
              value: d.AreaSales.TotalAreaSales
            },
            {
              name: 'Median Lot Size',
              value: d.AreaSales.MedianLotSize
            },
            {
              name: 'Median Living Area',
              value: d.AreaSales.MedianLivingArea
            },
            {
              name: 'Price Range Min',
              value: d.AreaSales.PriceRangeMin
            },
            {
              name: 'Price Range Max',
              value: d.AreaSales.PriceRangeMax
            },
            {
              name: 'Median Value',
              value: d.AreaSales.MedianValue
            },
            {
              name: 'Median Num Beds',
              value: d.AreaSales.MedianNumBeds
            },
            {
              name: 'Median Num Baths',
              value: d.AreaSales.MedianNumBaths
            },
            {
              name: 'Median Year Build',
              value: d.AreaSales.MedianYearBuilt
            }
          ],
          title: 'Area Sales'
        });
      }

      if (d.ComparableSales) {
        d.ComparableSales.forEach((c: ComparableSales, index: number) => {
          exportData.data.body.report.sections.push({
            items: [
              {
                name: 'Address',
                value: c.SiteAddress
              },
              {
                name: 'Unit',
                value: c.SiteUnit
              },
              {
                name: 'Unit Type',
                value: c.SiteUnitType
              },
              {
                name: 'City',
                value: c.SiteCity
              },
              {
                name: 'Recording Date',
                value: c.RecordingDate
              },
              {
                name: 'Sale Price',
                value: c.SalePrice
              },
              {
                name: 'Price Per SqFt',
                value: c.PricePerSQFT
              },
              {
                name: 'Building Area',
                value: c.bldgSize
              },
              {
                name: 'Lot Size',
                value: c.lotSize
              },
              {
                name: 'Bedrooms',
                value: c.Bedrooms
              },
              {
                name: 'Baths',
                value: c.Baths
              }
            ],
            title: `Comparable Sale ${index + 1}`
          });
        });
      }

      if (d.Neighborhood) {
        const neighbors = {
          items: [],
          title: 'Neighbors'
        };
        d.Neighborhood.forEach((n: Neighborhood) => {
          neighbors.items.push({
            name: 'Owner Name',
            value: n.OwnerName
          });
          neighbors.items.push({
            name: 'Address',
            value: n.SiteAddress
          });
        });
        exportData.data.body.report.sections.push(neighbors);
      }

      const propertyInfo = {
        ...pick(d, ['PrimaryOwnerName', 'SalesPrice', 'Bedrooms', 'Baths', 'APN']),
        reportDate: '',
        title: ''
      };
      if (d.TransferDate) {
        propertyInfo.reportDate = new Date(
          d.TransferDate.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3')
        ).toLocaleDateString('en-US');
      }

      propertyInfo.title =
        providerId === REP_DATA_RESPONSE_PROVIDER_ID_LPS
          ? `${d.SiteAddress} #${d.SiteUnit} ${d.SiteCity}, ${d.SiteState} ${d.SiteZip}`
          : `${d.SiteAddress} ${d.SiteCity}, ${d.SiteState} ${d.SiteZip}`;

      return {
        ...state,
        additionalData: {
          address: `${d.SiteAddress} ${d.SiteUnitType} ${d.SiteUnit}, ${d.SiteCity} ${d.SiteState}`,
          fips: d.fips,
          taxAmount: d.TaxAmount,
          zip: `${d.SiteZip}`
        },
        error: null,
        exportPropertyProfileData: exportData,
        pending: false,
        succeeded: true,
        viewProfile: {
          data: {
            areaSales: pick(d.AreaSales, ['MedianValue', 'MedianNumBeds', 'MedianNumBaths']),
            propertyInfo
          },
          detailsCharacteristics: {
            ownerInfo: pick(d, [
              'PrimaryOwnerName',
              'SecondaryOwnerName',
              'mailingAddress',
              'mailingUnit',
              'mailingCity',
              'mailingState',
              'mailingZip'
            ]),
            propertyCharacteristics: pick(d, [
              'YearBuilt',
              'BuildingArea',
              'LotSize',
              'Bedrooms',
              'Baths',
              'GarageType',
              'GarageNumCars',
              'TotalRooms',
              'Fireplace',
              'NumUnits',
              'Zoning',
              'Pool',
              'UseCode',
              'Basement'
            ]),
            propertyDetails: pick(d, [
              'SiteAddress',
              'SiteCity',
              'SiteState',
              'SiteZip',
              'APN',
              'SiteUnit',
              'SiteUnitType',
              'CityMuniTwp',
              'AssessedValue',
              'TaxAmount',
              'LegalBriefDescription'
            ])
          },
          platMap: {
            platMapData: d.PlatMapData,
            platMapEncoding: d.PlatMapEncoding,
            platMapType: d.PlatMapType
          },
          sales: {
            ...state.viewProfile.sales,
            comparableSales: d.ComparableSales,
            compareWith: {
              search: title,
              shortAddress: d.SiteAddress
            },
            saleInfo: d.AreaSales
          },
          title,
          transactions: d.TransHistory
        }
      };
    }

    case propertyDataActions.PropertyProfileActionTypes.SetComparableSalesMarkers: {
      return {
        ...state,
        viewProfile: {
          ...state.viewProfile,
          sales: {
            ...state.viewProfile.sales,
            markers: action.payload
          }
        }
      };
    }

    case propertySearchAction.SearchTypesActionTypes.GetSearchResultsFail:
    case parseActions.ParseActionTypes.GetPropertyProfileFail: {
      return {
        ...state,
        error: action.payload,
        pending: false,
        succeeded: false
      };
    }

    case propertyDataActions.PropertyProfileActionTypes.ResetSelectedProfile: {
      return initialState;
    }

    case propertyDataActions.PropertyProfileActionTypes.SavePropertyProfileScroll: {
      return {
        ...state,
        scrollPosition: action.payload
      };
    }

    default: {
      return state;
    }
  }
}

export const getProfileData = (state: State) => state.viewProfile.data;
export const getProfileTitle = (state: State) => state.viewProfile.title;
export const getDetailsCharacteristicsData = (state: State) => state.viewProfile.detailsCharacteristics;
export const getTransactionsData = (state: State) => state.viewProfile.transactions;
export const getSalesInfoData = (state: State) => state.viewProfile.sales.saleInfo;
export const getComparableSalesData = (state: State) => state.viewProfile.sales.comparableSales;
export const getComparableWith = (state: State) => state.viewProfile.sales.compareWith;
export const getComparableSalesMarkers = (state: State) => state.viewProfile.sales.markers;
export const getPlatMapData = (state: State) => state.viewProfile.platMap;
export const getProfileAdditionalData = (state: State) => state.additionalData;
export const getProfileScrollPosition = (state: State) => state.scrollPosition;

export const getNetSheetAddressDataFromLoadedProperty = (state: State) => {
  const capitalizeStrings = (rawString: string) => {
    let result = '';

    if (rawString) {
      const rawWords = rawString.split(' ');
      const resultWords = rawWords.map((word: string) => capitalize(word));
      result = resultWords.join(' ');
    }

    return result;
  };

  const rawCityName = state?.viewProfile?.detailsCharacteristics?.propertyDetails?.SiteCity;
  const city = capitalizeStrings(rawCityName);

  const rawAddress = state?.viewProfile?.detailsCharacteristics?.propertyDetails?.SiteAddress;
  const address = capitalizeStrings(rawAddress);

  return {
    address,
    city,
    fips: state?.additionalData?.fips,
    zip: state?.additionalData?.zip
  };
};

export const getSucceeded = (state: State) => state.succeeded;
