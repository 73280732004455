import { UrlTree } from '@angular/router';

import { createFeatureSelector, createSelector } from '@ngrx/store';

import { monetizationFeatureKey, MonetizationState } from '../reducers/monetization.reducer';

const selectMonetizationFeature = createFeatureSelector<MonetizationState>(monetizationFeatureKey);

export const selectMonetization = createSelector(selectMonetizationFeature, (state: MonetizationState) => state);

export const selectMonetizationData = createSelector(selectMonetization, (state: MonetizationState) => state.data);

export const selectIsPurchasePaymentLoading = createSelector(
  selectMonetizationData,
  (state) => state.mobile.isPaymentLoading
);

export const selectIsMonetizationEnabled = createSelector(selectMonetizationData, (state) => state.isEnabled);

export const selectIsMonetizationReady = createSelector(selectMonetizationData, (state) =>
  state.isEnabled ? state.isLoaded : true
);

export const selectIsPrimarySubscription = createSelector(
  selectMonetizationData,
  (state) => state.subscriptions.primary
);

export const selectPrimarySubscriptionPackage = createSelector(
  selectIsPrimarySubscription,
  (subscription) => subscription.package
);

export const selectPrimarySubscriptionPackagePlatformProductIdentifier = createSelector(
  selectPrimarySubscriptionPackage,
  (monetizationPackage) => monetizationPackage.platformProductIdentifier
);

export const selectPrimarySubscriptionPlanAccessAlias = createSelector(
  selectIsPrimarySubscription,
  (subscription) => subscription.accessAlias
);

export const selectPrimarySubscriptionIsActive = createSelector(
  selectIsPrimarySubscription,
  (subscription) => subscription.isActive
);

export const selectPrimarySubscriptionPlatformProductIdentifier = createSelector(
  selectIsPrimarySubscription,
  (subscription) => subscription.package.platformProductIdentifier
);

export const selectWebSubscriptions = createSelector(selectMonetizationData, (state) => state.web.subscriptions);

export const selectWebSubscriptionsList = createSelector(selectWebSubscriptions, (subscriptions) => subscriptions.list);

export const selectWebSubscriptionsLoading = createSelector(
  selectWebSubscriptions,
  (subscriptions) => subscriptions.isLoading
);

export const selectInAppPurchases = createSelector(selectMonetizationData, (state) => state.inAppPurchases);

export const selectInAppPurchasesFarmPurchase = createSelector(
  selectInAppPurchases,
  (inAppPurchases) => inAppPurchases.farmPurchase
);

export const selectInAppPurchasesFarmPurchaseIsEnabled = createSelector(
  selectInAppPurchasesFarmPurchase,
  (configuration) => configuration.isEnabled
);

export const selectInAppPurchasesFarmPurchaseIsMade = createSelector(
  selectInAppPurchasesFarmPurchase,
  (configuration) => configuration.isMade
);

export const selectInAppPurchasesPhoneAndEmailPreview = createSelector(
  selectInAppPurchasesFarmPurchase,
  (configuration) => configuration.phoneAndEmail.preview
);

export const selectInAppPurchasesPhoneAndEmailIsPurchaseCompleted = createSelector(
  selectInAppPurchasesFarmPurchase,
  (configuration) => configuration.phoneAndEmail.isPurchaseCompleted
);

export const selectInAppPurchasesPhoneAndEmailIsPurchaseSuccess = createSelector(
  selectInAppPurchasesFarmPurchase,
  (configuration) => configuration.phoneAndEmail.isPurchaseSuccess
);

export const selectMonetizationStore = createSelector(selectMonetizationData, (state) => state.store);

export const selectStoreProductIdentifiers = createSelector(
  selectMonetizationStore,
  (monetizationStore) => monetizationStore.storeProductIdentifiers
);

export const selectStoreIsProductDataLoaded = createSelector(
  selectMonetizationStore,
  (monetizationStore) => monetizationStore.isProductDataLoaded
);

export const selectStoreProductData = createSelector(
  selectMonetizationStore,
  (monetizationStore) => monetizationStore.productData
);

export const selectIsStoreProductIdentifierValid = (productId: string) =>
  createSelector(selectMonetizationStore, (monetizationStore) =>
    monetizationStore.storeProductIdentifiers.includes(productId)
  );

export const selectCanActivateStorePage = (redirectPath: UrlTree) =>
  createSelector(selectIsMonetizationEnabled, (isEnabled) => isEnabled || redirectPath);

export const selectViewedProduct = createSelector(selectMonetizationData, (state) => state.viewedProduct);

export const selectViewedProductData = createSelector(selectViewedProduct, (viewedProduct) => viewedProduct.data);

export const selectViewedProductIsLoading = createSelector(
  selectViewedProduct,
  (viewedProduct) => viewedProduct.isLoading
);

export const selectCanActivateProductDetailsPage = (productId: string, redirectPath: UrlTree) =>
  createSelector(
    selectMonetizationStore,
    selectIsStoreProductIdentifierValid(productId),
    selectViewedProductData,
    (monetizationStore, isStoreProductIdentifierValid, viewedProduct) =>
      (monetizationStore.isProductDataLoaded &&
        (isStoreProductIdentifierValid || !!viewedProduct.platformProductIdentifier)) ||
      redirectPath
  );
