<smd-desktop-tabs *ngIf="!isMobileView && !hideHeader"></smd-desktop-tabs>

<ion-header *ngIf="isMobileView && !hideMobileHeader && !hideHeader">
  <ion-toolbar class="ion-no-padding">
    <ion-grid class="ion-no-padding">
      <ion-row>
        <ion-col class="ion-no-padding tw-flex tw-flex-col">
          <ng-container *ngIf="!hasHeaderContent; else headerContent">
            <img
              class="tw-m-auto tw-max-h-14 tw-px-2 tw-py-1"
              [src]="logoUrl"
              alt="{{ brandName + ' logo' }}"
              (click)="goHome()"
            />
          </ng-container>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
</ion-header>

<ng-container *ngIf="!isMobileView && hasHeaderContent && !hideHeader">
  <ng-container *ngTemplateOutlet="headerContent"></ng-container>
</ng-container>

<ng-content></ng-content>

<ng-container *ngTemplateOutlet="isMobileView ? mobileFooterTemplate : desktopFooterTemplate"></ng-container>

<ng-template #headerContent>
  <smd-header-content
    [goBackToPage]="goBackToPage"
    [title]="pageTitle"
    [subTitle]="pageSubTitle"
    [hasBackNavigation]="hasBackNavigation"
    [backNavigationText]="backNavigationText"
    [hasIcon]="hasIcon"
    [rightIcons]="rightIcons"
    [rightButtons]="rightButtons"
    [closeIcon]="closeIcon"
    (rightButtonClick)="onRightButtonClick($event)"
    (leftButtonClick)="onLeftButtonClick($event)"
  ></smd-header-content>
</ng-template>

<ng-template #desktopFooterTemplate>
  <ion-footer *ngIf="!hideFooter" class="smd-page-footer">
    <smd-contact-card *ngIf="!hideContactCard" class="tw-max-w-full"></smd-contact-card>
  </ion-footer>
</ng-template>

<ng-template #mobileFooterTemplate>
  <smd-contact-card
    *ngIf="!hideFooter && !hideContactCard"
    class="tw-absolute tw-bottom-16 tw-w-full tw-max-w-full"
    [ngClass]="{
      'card-collapsed': !expandContactCard,
      'card-expanded': expandContactCard
    }"
  ></smd-contact-card>
</ng-template>

<smd-mobile-tabs *ngIf="isMobileView && !hideFooter"></smd-mobile-tabs>
