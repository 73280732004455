import { Component, NgZone, OnInit } from '@angular/core';

import { distinctUntilChanged, throttleTime, withLatestFrom } from 'rxjs/operators';

import { NavTabsItem } from '../../../../typings/layout';

import { GenericComponent } from '../../generic/generic.component';

import { KeyboardService } from '../../../services/keyboard/keyboard.service';
import { LayoutService } from '../../../services/layout/layout.service';

@Component({
  selector: 'smd-mobile-tabs',
  styleUrls: ['./mobile-tabs.component.scss'],
  templateUrl: './mobile-tabs.component.html'
})
export class MobileTabsComponent extends GenericComponent implements OnInit {
  viewTabLinks = false;
  tabs: NavTabsItem[] = [];

  constructor(
    private ngZone: NgZone,
    private keyboardService: KeyboardService,
    private layoutService: LayoutService
  ) {
    super();
  }

  ngOnInit() {
    this.addUniqueSubscription(
      'tabsDataSubscription',
      this.layoutService.getNavTabsData().subscribe((tabsData) => {
        this.ngZone.run(() => {
          this.tabs = tabsData;
        });
      })
    );

    this.addUniqueSubscription(
      'tabsStateSubscription',
      this.layoutService
        .getNavTabsState()
        .pipe(distinctUntilChanged(), throttleTime(300, undefined, { leading: true, trailing: true }))
        .subscribe((navTabsState) => {
          this.ngZone.run(() => {
            this.viewTabLinks = navTabsState.isVisible;
          });
        })
    );

    this.addUniqueSubscription(
      'keyboardSettingsSubscription',
      this.keyboardService
        .getHandlerSettings()
        .pipe(withLatestFrom(this.layoutService.getNavTabsState()))
        .subscribe(([keyboardSettings, navTabsState]) => {
          this.ngZone.run(() => {
            this.viewTabLinks = this.layoutService.checkTabsVisibilityByKeyboard(
              keyboardSettings?.isVisible || navTabsState.isVisible
            );
          });
        })
    );
  }

  handleTabNavigation(route: string[]) {
    this.layoutService.handleTabNavigation(route);
  }

  trackByActiveTab(index: number, tabItem: NavTabsItem) {
    return tabItem.route;
  }
}
