import { Injectable } from '@angular/core';
import { NavController, Platform } from '@ionic/angular';

import { Store } from '@ngrx/store';
import { NetsheetConfigService, OrganizationEnum } from '@redshed/netsheet-calculator';
import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic';
import { isEqual, isNil } from 'lodash-es';
import { of, throwError } from 'rxjs';
import { concatMap, distinctUntilChanged, filter, map, withLatestFrom } from 'rxjs/operators';

import { ENV } from '../environments/environment';
import { InternalStorageData } from './typings/app';
import { DeviceData } from './typings/device';

import * as fromAppConfigActions from './store/actions/app-config/general-info';
import * as fromAppConfigGeneralInfoActions from './store/actions/app-config/general-info';
import * as fromAppConfigMapDataActions from './store/actions/app-config/map-data';
import * as fromAppSettingsActions from './store/actions/app-settings/user-profile';
import * as fromMapActions from './store/actions/map.actions';
import * as parseActions from './store/actions/parse/parse-implementation';
import { mapFeatureKey } from './store/reducers/map.reducer';
import * as fromAppConfig from './store/selectors/app-config/index';
import * as fromAppSettings from './store/selectors/app-settings';

import { DeviceService } from './common/services/device/device.service';
import { PlatformDetectService } from './common/services/platform-detect/platform-detect.service';
import { SecureStorageService } from './common/services/secure-storage/secure-storage.service';
import { ThemeService } from './common/services/theme/theme.service';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  private isMobilePlatform = true;

  constructor(
    private deviceService: DeviceService,
    private navController: NavController,
    private netSheetConfigService: NetsheetConfigService,
    private platform: Platform,
    private platformDetectService: PlatformDetectService,
    private secureStorageService: SecureStorageService,
    private store: Store,
    private themeService: ThemeService
  ) {
    this.platform.resume
      .pipe(
        withLatestFrom(this.store.select(fromAppSettings.getAppSettingsUserProfileData)),
        filter(([_action, user]) => !isNil(user?.id))
      )
      .subscribe(() => {
        this.store.dispatch(new fromAppConfigActions.GetAppSettingsData());
      });

    this.platformDetectService.getIsMobileSubscription().subscribe((isMobilePlatform) => {
      this.isMobilePlatform = isMobilePlatform;
    });

    this.store
      .select(fromAppSettings.getAppSettingsUserProfileData)
      .pipe(filter((user) => !isNil(user?.id)))
      .subscribe((user) => {
        this.store.dispatch(new parseActions.UpdateUserToken(user.id));
      });
  }

  init() {
    this.platform.ready().then(() => {
      this.onAppInit();

      this.platform.backButton.subscribeWithPriority(10, () => {
        this.navController.back();
      });
    });
  }

  private onAppInit() {
    // Get the geolocation on app start
    this.getLocation();

    // Get app config data
    this.getAppConfig();

    // Collect needed Device info
    this.getDeviceData();

    this.checkIfAuthenticated();
  }

  private getLocation() {
    this.store.dispatch(new fromAppConfigMapDataActions.GetInitialLocation());
  }

  private getDeviceData() {
    this.store.dispatch(new fromAppConfigGeneralInfoActions.GetDeviceData());
  }

  private getAppConfig() {
    this.store
      .select(fromAppConfig.getAppInfoColors)
      .pipe(filter((colors) => !!colors))
      .subscribe(
        (colors) => {
          this.netSheetConfigService.setColors(colors.primary, colors.secondary);
          this.themeService.setColors(colors);
        },
        (error) => {
          return throwError(error);
        }
      );

    this.netSheetConfigService.setOrganization(OrganizationEnum.OLD_REPUBLIC_TITLE);

    this.store
      .select(fromAppSettings.getAppSettingsUserProfileData)
      .pipe(filter((user) => !isNil(user)))
      .subscribe(
        (user) => {
          this.netSheetConfigService.updatePrefill({
            userCompany: user.company,
            userEmail: user.email,
            userFirstName: user.firstName,
            userLastName: user.lastName,
            userPhone: user.phone
          });
        },
        (error) => {
          return throwError(error);
        }
      );
  }

  private checkIfAuthenticated() {
    this.secureStorageService
      .getStorageDataAsObservable('app')
      .pipe(
        distinctUntilChanged((oldValue, newValue) => !isEqual(oldValue, newValue)),
        concatMap((data) => {
          let observable = of<InternalStorageData>(data);

          if (this.isMobilePlatform) {
            observable = this.deviceService.getInfo().pipe(
              filter((deviceData: DeviceData) => !!deviceData),
              map((deviceData: DeviceData) => ({
                ...data,
                deviceData: {
                  ...(data?.deviceData || {}),
                  ...deviceData
                }
              }))
            );
          }

          return observable;
        }),
        filter((storageData) => storageData?.isAuthenticated)
      )
      .subscribe(async (storageData) => {
        await this.handleNotifications();

        this.store.dispatch(new fromAppConfigGeneralInfoActions.UpdateGeneralInfoFromInternalStorage(storageData));
        this.store.dispatch(
          new fromAppSettingsActions.UpdateSalesInfoFromInternalStorage({
            salesInfo: storageData.salesInfo
          })
        );
        this.store.dispatch(
          new fromAppSettingsActions.UpdateUserFromInternalStorage({
            user: {
              ...storageData.user,
              id: storageData.user?.id || storageData.authData?.userObjectId // I give up
            }
          })
        );

        if (storageData.authData?.repCode) {
          this.store.dispatch(new fromAppConfigActions.GetAppSettingsData());
        }

        // FIXME: Map Centering Functionality - for now, this will be available only in dev builds
        if (!ENV.production && !!storageData[mapFeatureKey]) {
          const isMapCentered = storageData[mapFeatureKey].isMapCentered;
          this.store.dispatch(fromMapActions.setMapCenterSetting({ isMapCentered }));
        }
      });
  }

  async setupNotifications() {
    if (this.isMobilePlatform) {
      const hasPermission = await FCM.hasPermission();
      if (!hasPermission) {
        await FCM.requestPushPermission({
          ios9Support: {
            interval: 0.3,
            timeout: 10
          }
        });
      }
    }
  }

  async handleNotifications() {
    if (this.isMobilePlatform) {
      const hasPermission = await FCM.hasPermission();

      if (hasPermission) {
        const iInitialPushPayload = await FCM.getInitialPushPayload();

        if (iInitialPushPayload) {
          await this.navController.navigateRoot(['/', 'home', 'walking-farm'], {
            state: {
              activeTab: 'savedReports'
            }
          });
        }

        const firebaseToken = await this.getFirebaseToken();
        this.store.dispatch(new parseActions.UpdateFirebaseToken({ firebaseToken }));

        FCM.onTokenRefresh().subscribe((firebaseToken) => {
          this.store.dispatch(new parseActions.UpdateFirebaseToken({ firebaseToken }));
        });
      }
    }
  }

  async getFirebaseToken() {
    let firebaseToken: string | null = null;

    if (this.isMobilePlatform) {
      const hasPermission = await FCM.hasPermission();

      if (hasPermission) {
        firebaseToken = await FCM.getToken();
      }
    }

    return firebaseToken;
  }
}
