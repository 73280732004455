import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

import { File } from '@awesome-cordova-plugins/file/ngx';
import { Keyboard, KeyboardResizeMode } from '@awesome-cordova-plugins/keyboard/ngx';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { saveAs } from 'file-saver';

import { fileConfigs } from '../../../constants/file';
import { PropertyData } from '../../../typings/walking-farm';

import { PfHelperService } from '../../../providers/pf-helper-service.service';
import { LoggerService } from '../logger/logger.service';
import { PlatformDetectService } from '../platform-detect/platform-detect.service';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  private isMobilePlatform = true;

  constructor(
    private file: File,
    private platform: Platform,
    private socialSharing: SocialSharing,
    private pfHelperService: PfHelperService,
    private platformDetectService: PlatformDetectService,
    private keyboard: Keyboard,
    private loggerService: LoggerService
  ) {
    this.platformDetectService.getIsMobileSubscription().subscribe((isMobilePlatform: boolean) => {
      this.isMobilePlatform = isMobilePlatform;
    });
  }

  async writeBlobToFs(blob: Blob, fileName: string, dataType?: string) {
    let result: Promise<string> = Promise.resolve(null);

    if (!this.isMobilePlatform) {
      this.downloadFileOnDesktop(blob, fileName, dataType ? dataType : fileConfigs.blob.dataType);
    } else {
      const path = this.platform.is('android') ? this.file.dataDirectory : this.file.documentsDirectory;

      if (this.file) {
        result = this.file
          .writeFile(path, fileName, blob, {
            replace: true
          })
          .then(
            (fileEntry) => fileEntry.nativeURL,
            () => {
              this.pfHelperService.showSystemError('Error writing the blob data!');
              return '';
            }
          );
      }
    }

    return result;
  }

  async transformStringToBlob(data: string, fileType: string) {
    let blob: Blob;
    let base64Response: Response;

    switch (fileType) {
      case fileConfigs.pdf.fileType:
        base64Response = await fetch(`data:${fileType};base64,${data}`);
        blob = await base64Response.blob();
        break;
      case fileConfigs.csv.fileType:
        blob = new Blob([data], { type: `${fileType};${fileConfigs.csv.encoding}` });
        break;
      case fileConfigs.plainText.textJsonType:
      case fileConfigs.plainText.textPlainType:
        blob = new Blob([data], { type: `${fileType};${fileConfigs.plainText.encoding}` });
        break;
      default:
        blob = null;
        break;
    }

    return blob;
  }

  openNativeShareSheet(fileUrl: string = null, fileName: string, message: string = null, subject: string = null) {
    const options = {
      files: [fileUrl],
      message,
      subject
    };

    this.keyboard.setResizeMode(KeyboardResizeMode.None);

    this.socialSharing.shareWithOptions(options).then(
      () => {
        this.keyboard.setResizeMode(KeyboardResizeMode.Native);
      },
      () => {
        this.keyboard.setResizeMode(KeyboardResizeMode.Native);
      }
    );
  }

  async sharePlainTextViaNativeShareSheet(property: PropertyData, appName: string) {
    const plainText = this.computeExportLeadText(property, appName);

    try {
      if (!this.isMobilePlatform) {
        const fileName = `${property.A002_HouseNumber}_${property.A004_StreetName}_${property.A046_City}_${property.A047_State}.txt`;
        const fileType = fileConfigs.plainText.fileType(fileName);
        const blob = await this.transformStringToBlob(plainText, fileType);
        this.downloadFileOnDesktop(blob, fileName, fileConfigs.plainText.dataType);
      } else {
        this.openNativeShareSheet(null, '', plainText, null);
      }
    } catch (error) {
      this.loggerService.logPapertrailError(error);
    }
  }

  private downloadFileOnDesktop(blob: Blob, fileName: string, type: string) {
    switch (type) {
      case fileConfigs.pdf.dataType:
        fileName = fileName.includes(fileConfigs.pdf.fileExtension)
          ? fileName
          : `${fileName}${fileConfigs.pdf.fileExtension}`;
        break;

      case fileConfigs.csv.dataType:
        fileName = fileName.includes(fileConfigs.csv.fileExtension)
          ? fileName
          : `${fileName}${fileConfigs.csv.fileExtension}`;
        break;

      default:
        break;
    }

    if (blob) {
      saveAs(blob, fileName);
    }
  }

  private computeExportLeadText(property: PropertyData, appName: string) {
    return `
    Lead Status: ${property.Status}
    Property Address
    ${property.A002_HouseNumber} ${property.A004_StreetName}
    ${property.A046_City} ${property.A047_State}
    --------
    Owner Information
    ${property.A009_OwnerName2}
    Phone: ${property.A044_OwnerPhone}
    Email: ${property.A045_OwnerEmail}
    --------
    Notes:
    ${property.Notes}
    --------
    Beds: ${property.A024_Bedrooms}
    Baths: ${property.A025_Bathrooms}
    Pool: ${property.A026_Pool}
    Sqft: ${property.A022_BuildingArea}
    Lot: ${property.A035_LotArea}
    Built: ${property.A038_YearBuilt}
    Use: ${property.A020_UseCodeDescription}
    --------
    Sale Price: ${property.A029_SalesPrice}
    Sale Date: ${property.A030_SaleDate}
    Doc #: ${property.A031_SalesDocumentNumber}
    Assessed: ${property.A032_AssessedLandValue}
    Impr: ${property.A034_AssessedImprovementValue}
    Zn: ${property.A021_Zoning}
    --------
    Sent from ${appName} a PropertyForce by Red Shed App
    `;
  }
}
