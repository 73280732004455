import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';

import { FormItem } from '../../../../typings/form';

import { GenericComponent } from '../../generic/generic.component';

import * as walkingFarmAction from '../../../../store/actions/walking-farm/farm';
import * as fromWalkingFarm from '../../../../store/selectors/walking-farm';

interface FarmNameEditFormGroup {
  name: FormControl<string>;
}

@Component({
  selector: 'smd-farm-name-edit-modal',
  templateUrl: './farm-name-edit-modal.component.html'
})
export class FarmNameEditModalComponent extends GenericComponent implements OnInit {
  isModalOpen = false;

  farmNameFieldItem: FormItem = {
    errorMsg: {
      pattern: 'The report name must contain only alphanumeric characters.'
    },
    label: 'Farm name',
    name: 'name',
    required: true,
    type: 'text'
  };
  form: FormGroup<FarmNameEditFormGroup> = new FormGroup<FarmNameEditFormGroup>({
    name: new FormControl(null, [Validators.required, Validators.pattern(/^[a-zA-Z0-9\s]*$/)])
  });

  errorMessage: string | null = null;

  @Input() set farmName(value: string) {
    if (value) {
      this.form.patchValue({
        name: value
      });
    }
  }

  @Input() set isOpen(check: boolean) {
    this.ngZone.run(() => {
      if (check !== this.isModalOpen) {
        this.toggleModal(check);
      }
    });
  }

  @Output() modalToggle: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private ngZone: NgZone,
    private store: Store
  ) {
    super();
  }

  ngOnInit() {
    this.addUniqueSubscription(
      'getWalkingFarmErrorSubscription',
      this.store
        .select(fromWalkingFarm.getWalkingFarmError)
        .pipe(filter((error: HttpErrorResponse) => !!error))
        .subscribe((error: HttpErrorResponse) => {
          this.ngZone.run(() => {
            this.errorMessage = error.error.description;
          });
        })
    );
  }

  onFieldValueChange() {
    this.ngZone.run(() => {
      this.errorMessage = null;
    });
  }

  onFarmNameEditModalDismiss() {
    this.ngZone.run(() => {
      if (this.isModalOpen) {
        this.isModalOpen = false;
        this.modalToggle.emit(false);
      }
    });
  }

  onSubmit() {
    if (this.form.valid) {
      this.store.dispatch(
        new walkingFarmAction.UpdateFarm({
          data: {
            ...this.form.value
          }
        })
      );
    }
  }

  private toggleModal(check: boolean) {
    this.isModalOpen = check;
  }
}
