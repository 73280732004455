import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { AgmCoreModule } from '@agm/core';
import { AgmMarkerClustererModule } from '@agm/markerclusterer';
import { NgxMaskModule } from 'ngx-mask';

import { AccordionItemComponent } from './accordion-item/accordion-item.component';
import { AffordabilityReportComponent } from './calculator-reports/affordability-report/affordability-report.component';
import { AmortizationReportComponent } from './calculator-reports/amortization-report/amortization-report.component';
import { PitiReportComponent } from './calculator-reports/piti-report/piti-report.component';
import { DoughnutChartComponent } from './chart/doughnut/doughnut-chart.component';
import { LineChartComponent } from './chart/line/line-chart.component';
import { ContactCardComponent } from './contact-card/contact-card.component';
import { ContentTabsComponent } from './content-tabs/content-tabs.component';
import { YearsCounterComponent } from './counter/counter.component';
import { DropdownMenuComponent } from './dropdown-menu/dropdown-menu.component';
import { FooterPaginationComponent } from './footer-pagination/footer-pagination.component';
import { FormErrorMessagesComponent } from './form/form-error-messages/form-error-messages.component';
import { FormLoadingComponent } from './form/form-loading/form-loading.component';
import { GenericComponent } from './generic/generic.component';
import { HeaderContentComponent } from './header-content/header-content.component';
import { DesktopTabsComponent } from './layouts/desktop/desktop-tabs.component';
import { MobileTabsComponent } from './layouts/mobile/mobile-tabs.component';
import { PageLayoutComponent } from './layouts/page/page-layout.component';
import { ListItemComponent } from './list-item/list-item.component';
import { MapComponent } from './map/map.component';
import { PhoneAndEmailModalComponent } from './monetization/phone-and-email-modal/phone-and-email-modal.component';
import { PropertyProfileItemComponent } from './property-profile-item/property-profile-item.component';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { ResultItemComponent } from './search-results/search-results.component';
import { TheOopsComponentComponent } from './the-oops-component/the-oops-component.component';
import { FarmNameEditModalComponent } from './walking-farm/edit-farm-name-modal/farm-name-edit-modal.component';

import { DirectivesModule } from '../directives/directives.module';

import { PipesModule } from '../pipes/pipes.module';

export const components = [
  AccordionItemComponent,
  AffordabilityReportComponent,
  AmortizationReportComponent,
  ContactCardComponent,
  ContentTabsComponent,
  DesktopTabsComponent,
  DoughnutChartComponent,
  DropdownMenuComponent,
  FarmNameEditModalComponent,
  FooterPaginationComponent,
  FormErrorMessagesComponent,
  FormLoadingComponent,
  GenericComponent,
  HeaderContentComponent,
  LineChartComponent,
  ListItemComponent,
  MapComponent,
  MobileTabsComponent,
  PageLayoutComponent,
  PhoneAndEmailModalComponent,
  PitiReportComponent,
  PropertyProfileItemComponent,
  ResultItemComponent,
  SearchBarComponent,
  TheOopsComponentComponent,
  YearsCounterComponent
];

@NgModule({
  declarations: [...components],
  exports: [...components],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    PipesModule,
    DirectivesModule,
    NgxMaskModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyD9BxeSvt3u--Oj-_GD-qG2nPr1uODrR0Y'
    }),
    AgmMarkerClustererModule
  ]
})
export class ComponentsModule {}
