import { Directive, ElementRef, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

import { isUndefined } from 'lodash-es';
import moment from 'moment';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[smdTextAreaLogs]'
})
export class TextAreaLogsDirective implements OnInit, OnDestroy {
  private currentValue: string;
  private currentValueChangeSubscription: Subscription;

  constructor(
    private elementRef: ElementRef,
    private ngControl: NgControl
  ) {}

  ngOnInit() {
    const view: ControlValueAccessor = this.ngControl?.valueAccessor;

    if (view) {
      view.registerOnChange((value: string) => {
        this.currentValue = value;
      });
    }
  }

  @HostListener('ionFocus', []) onIonFocus() {
    this.currentValue = isUndefined(this.currentValue) ? this.ngControl?.control?.value : this.currentValue;

    const latestNotesDate = moment()
      .locale(navigator?.language || 'en-US')
      .format('L, LTS');
    let notes = `${latestNotesDate}: `;
    if (this.currentValue) {
      notes += `\n\n${this.currentValue}`;
    }

    this.ngControl?.control.setValue(notes);
    this.ngControl?.valueAccessor.writeValue(notes);

    setTimeout(async () => {
      const textarea = await this.elementRef.nativeElement.getInputElement();
      textarea.setSelectionRange(latestNotesDate.length + 2, latestNotesDate.length + 2);
    });
  }

  @HostListener('ionBlur', []) onIonBlur() {
    this.currentValue = isUndefined(this.currentValue) ? this.ngControl?.control?.value : this.currentValue;

    let notes = this.currentValue;

    const regex = /^(\d{2}\/){2}\d{4},\s(\d{2}:){3}\s*($|\n{2}.*)/;

    if (regex.test(notes)) {
      notes = notes.replace(/^(\d{2}\/){2}\d{4},\s(\d{2}:){3}\s*\n{2}/, '');
    }

    this.ngControl?.control.setValue(notes);
    this.ngControl?.valueAccessor.writeValue(notes);
  }

  ngOnDestroy() {
    if (this.currentValueChangeSubscription) {
      this.currentValueChangeSubscription.unsubscribe();
    }
  }
}
